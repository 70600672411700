import { gql } from "@/graphql/apollo";

export const CUSTOMERS_QUERY = gql`
  query CustomersTable(
    $id: Int
    $cpf: String
    $cpfs: [String]
    $email: String
    $hasValidEmail: Boolean
    $name: String
    $address: String
    $phone: String
    $hasValidPhone: Boolean
    $businessIds: [Int]
    $isOptIn: Boolean
    $isOptInBusiness: Boolean
    $isOptInCustomer: Boolean
    $isOptOut: Boolean
    $dayBirthday: Boolean
    $weekBirthday: Boolean
    $monthBirthday: Boolean
    $birthdayFrom: Date
    $birthdayTo: Date
    $purchaseDateFrom: DateTime
    $purchaseDateTo: DateTime
    $purchaseHourFrom: Time
    $purchaseHourTo: Time
    $purchaseValueFrom: Float
    $purchaseValueTo: Float
    $purchaseCountFrom: Int
    $purchaseCountTo: Int
    $purchaseCountGroupPeriod: Period
    $daysWithoutBuying: Int
    $limitDaysWithoutBuying: Int
    $ageFrom: Int
    $ageTo: Int
    $genders: [Gender]
    $classifications: [CustomerClassification]
    $saleOrigins: [String]
    $districts: [String]
    $cities: [Int]
    $cityNames: [String]
    $states: [String]
    $productIds: [Int]
    $productGroupIds: [Int]
    $productGroupCategoryIds: [Int]
    $isProductsSamePurchase: Boolean
    $isGroupQuery: Boolean
    $daysBuyProduct: Int
    $limitDaysBuyProduct: Int
    $buyProductBegin: DateTime
    $buyProductUntil: DateTime
    $daysNoBuyProduct: Int
    $limitDaysNoBuyProduct: Int
    $noBuyProductBegin: DateTime
    $noBuyProductUntil: DateTime
    $tagIds: [Int]
    $wasEnriched: Boolean
    $averageTicketFrom: Float
    $averageTicketTo: Float
    $disregardedIds: [Int]
    $page: Int
    $maxPerPage: Int
    $sortBy: String
    $orderBy: OrderBy
  ) {
    Customers(
      id: $id
      cpf: $cpf
      cpfs: $cpfs
      email: $email
      hasValidEmail: $hasValidEmail
      name: $name
      address: $address
      phone: $phone
      hasValidPhone: $hasValidPhone
      businessIds: $businessIds
      isOptIn: $isOptIn
      isOptInBusiness: $isOptInBusiness
      isOptInCustomer: $isOptInCustomer
      isOptOut: $isOptOut
      dayBirthday: $dayBirthday
      weekBirthday: $weekBirthday
      monthBirthday: $monthBirthday
      birthdayFrom: $birthdayFrom
      birthdayTo: $birthdayTo
      purchaseDateFrom: $purchaseDateFrom
      purchaseDateTo: $purchaseDateTo
      purchaseHourFrom: $purchaseHourFrom
      purchaseHourTo: $purchaseHourTo
      purchaseValueFrom: $purchaseValueFrom
      purchaseValueTo: $purchaseValueTo
      purchaseCountFrom: $purchaseCountFrom
      purchaseCountTo: $purchaseCountTo
      purchaseCountGroupPeriod: $purchaseCountGroupPeriod
      daysWithoutBuying: $daysWithoutBuying
      limitDaysWithoutBuying: $limitDaysWithoutBuying
      ageFrom: $ageFrom
      ageTo: $ageTo
      genders: $genders
      classifications: $classifications
      saleOrigins: $saleOrigins
      districts: $districts
      cities: $cities
      cityNames: $cityNames
      states: $states
      productIds: $productIds
      productGroupIds: $productGroupIds
      productGroupCategoryIds: $productGroupCategoryIds
      isProductsSamePurchase: $isProductsSamePurchase
      isGroupQuery: $isGroupQuery
      daysBuyProduct: $daysBuyProduct
      limitDaysBuyProduct: $limitDaysBuyProduct
      buyProductBegin: $buyProductBegin
      buyProductUntil: $buyProductUntil
      daysNoBuyProduct: $daysNoBuyProduct
      limitDaysNoBuyProduct: $limitDaysNoBuyProduct
      noBuyProductBegin: $noBuyProductBegin
      noBuyProductUntil: $noBuyProductUntil
      tagIds: $tagIds
      wasEnriched: $wasEnriched
      averageTicketFrom: $averageTicketFrom
      averageTicketTo: $averageTicketTo
      disregardedIds: $disregardedIds
      page: $page
      maxPerPage: $maxPerPage
      sortBy: $sortBy
      orderBy: $orderBy
    ) {
      data {
        id
        cpf
        name
        phone
        email
      }
      pagination {
        currentPage
        nextPage
        maxPerPage
        totalItems
        totalPages
      }
      countEnriched
      countPhone
      countEmail
    }
  }
`;
export const CUSTOMERS_COMPLEMENTS_QUERY = gql`
  query CustomersComplementTable(
    $id: Int
    $cpf: String
    $cpfs: [String]
    $email: String
    $hasValidEmail: Boolean
    $name: String
    $address: String
    $phone: String
    $hasValidPhone: Boolean
    $businessIds: [Int]
    $isOptIn: Boolean
    $isOptInBusiness: Boolean
    $isOptInCustomer: Boolean
    $isOptOut: Boolean
    $dayBirthday: Boolean
    $weekBirthday: Boolean
    $monthBirthday: Boolean
    $birthdayFrom: Date
    $birthdayTo: Date
    $purchaseDateFrom: DateTime
    $purchaseDateTo: DateTime
    $purchaseHourFrom: Time
    $purchaseHourTo: Time
    $purchaseValueFrom: Float
    $purchaseValueTo: Float
    $purchaseCountFrom: Int
    $purchaseCountTo: Int
    $purchaseCountGroupPeriod: Period
    $daysWithoutBuying: Int
    $limitDaysWithoutBuying: Int
    $ageFrom: Int
    $ageTo: Int
    $genders: [Gender]
    $classifications: [CustomerClassification]
    $saleOrigins: [String]
    $districts: [String]
    $cities: [Int]
    $cityNames: [String]
    $states: [String]
    $productIds: [Int]
    $productGroupIds: [Int]
    $productGroupCategoryIds: [Int]
    $isProductsSamePurchase: Boolean
    $isGroupQuery: Boolean
    $daysBuyProduct: Int
    $limitDaysBuyProduct: Int
    $buyProductBegin: DateTime
    $buyProductUntil: DateTime
    $daysNoBuyProduct: Int
    $limitDaysNoBuyProduct: Int
    $noBuyProductBegin: DateTime
    $noBuyProductUntil: DateTime
    $tagIds: [Int]
    $wasEnriched: Boolean
    $averageTicketFrom: Float
    $averageTicketTo: Float
    $disregardedIds: [Int]
    $page: Int
    $maxPerPage: Int
    $sortBy: String
    $orderBy: OrderBy
  ) {
    Customers(
      id: $id
      cpf: $cpf
      cpfs: $cpfs
      email: $email
      hasValidEmail: $hasValidEmail
      name: $name
      address: $address
      phone: $phone
      hasValidPhone: $hasValidPhone
      businessIds: $businessIds
      isOptIn: $isOptIn
      isOptInBusiness: $isOptInBusiness
      isOptInCustomer: $isOptInCustomer
      isOptOut: $isOptOut
      dayBirthday: $dayBirthday
      weekBirthday: $weekBirthday
      monthBirthday: $monthBirthday
      birthdayFrom: $birthdayFrom
      birthdayTo: $birthdayTo
      purchaseDateFrom: $purchaseDateFrom
      purchaseDateTo: $purchaseDateTo
      purchaseHourFrom: $purchaseHourFrom
      purchaseHourTo: $purchaseHourTo
      purchaseValueFrom: $purchaseValueFrom
      purchaseValueTo: $purchaseValueTo
      purchaseCountFrom: $purchaseCountFrom
      purchaseCountTo: $purchaseCountTo
      purchaseCountGroupPeriod: $purchaseCountGroupPeriod
      daysWithoutBuying: $daysWithoutBuying
      limitDaysWithoutBuying: $limitDaysWithoutBuying
      ageFrom: $ageFrom
      ageTo: $ageTo
      genders: $genders
      classifications: $classifications
      saleOrigins: $saleOrigins
      districts: $districts
      cities: $cities
      cityNames: $cityNames
      states: $states
      productIds: $productIds
      productGroupIds: $productGroupIds
      productGroupCategoryIds: $productGroupCategoryIds
      isProductsSamePurchase: $isProductsSamePurchase
      isGroupQuery: $isGroupQuery
      daysBuyProduct: $daysBuyProduct
      limitDaysBuyProduct: $limitDaysBuyProduct
      buyProductBegin: $buyProductBegin
      buyProductUntil: $buyProductUntil
      daysNoBuyProduct: $daysNoBuyProduct
      limitDaysNoBuyProduct: $limitDaysNoBuyProduct
      noBuyProductBegin: $noBuyProductBegin
      noBuyProductUntil: $noBuyProductUntil
      tagIds: $tagIds
      wasEnriched: $wasEnriched
      averageTicketFrom: $averageTicketFrom
      averageTicketTo: $averageTicketTo
      disregardedIds: $disregardedIds
      page: $page
      maxPerPage: $maxPerPage
      sortBy: $sortBy
      orderBy: $orderBy
    ) {
      data {
        id
        totalSpend
        averageSpend
        lastPurcharseAt
        purcharseCount
      }
      pagination {
        currentPage
        nextPage
        maxPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const CUSTOMERS_CSV_QUERY = gql`
  query CustomersToCSV(
    $id: Int
    $cpf: String
    $cpfs: [String]
    $email: String
    $hasValidEmail: Boolean
    $name: String
    $address: String
    $phone: String
    $hasValidPhone: Boolean
    $businessIds: [Int]
    $businessGroupId: Int
    $isOptIn: Boolean
    $isOptInBusiness: Boolean
    $isOptInCustomer: Boolean
    $isOptOut: Boolean
    $dayBirthday: Boolean
    $weekBirthday: Boolean
    $monthBirthday: Boolean
    $birthdayFrom: Date
    $birthdayTo: Date
    $purchaseDateFrom: DateTime
    $purchaseDateTo: DateTime
    $purchaseHourFrom: Time
    $purchaseHourTo: Time
    $purchaseValueFrom: Float
    $purchaseValueTo: Float
    $purchaseCountFrom: Int
    $purchaseCountTo: Int
    $purchaseCountGroupPeriod: Period
    $daysWithoutBuying: Int
    $limitDaysWithoutBuying: Int
    $ageFrom: Int
    $ageTo: Int
    $genders: [Gender]
    $classifications: [CustomerClassification]
    $saleOrigins: [String]
    $districts: [String]
    $productIds: [Int]
    $productGroupIds: [Int]
    $productGroupCategoryIds: [Int]
    $isProductsSamePurchase: Boolean
    $tagIds: [Int]
    $wasEnriched: Boolean
    $averageTicketFrom: Float
    $averageTicketTo: Float
    $disregardedIds: [Int]
    $page: Int
    $maxPerPage: Int
    $sortBy: String
    $orderBy: OrderBy
  ) {
    Customers(
      id: $id
      cpf: $cpf
      cpfs: $cpfs
      email: $email
      hasValidEmail: $hasValidEmail
      name: $name
      address: $address
      phone: $phone
      hasValidPhone: $hasValidPhone
      businessIds: $businessIds
      businessGroupId: $businessGroupId
      isOptIn: $isOptIn
      isOptInBusiness: $isOptInBusiness
      isOptInCustomer: $isOptInCustomer
      isOptOut: $isOptOut
      dayBirthday: $dayBirthday
      weekBirthday: $weekBirthday
      monthBirthday: $monthBirthday
      birthdayFrom: $birthdayFrom
      birthdayTo: $birthdayTo
      purchaseDateFrom: $purchaseDateFrom
      purchaseDateTo: $purchaseDateTo
      purchaseHourFrom: $purchaseHourFrom
      purchaseHourTo: $purchaseHourTo
      purchaseValueFrom: $purchaseValueFrom
      purchaseValueTo: $purchaseValueTo
      purchaseCountFrom: $purchaseCountFrom
      purchaseCountTo: $purchaseCountTo
      purchaseCountGroupPeriod: $purchaseCountGroupPeriod
      daysWithoutBuying: $daysWithoutBuying
      limitDaysWithoutBuying: $limitDaysWithoutBuying
      ageFrom: $ageFrom
      ageTo: $ageTo
      genders: $genders
      classifications: $classifications
      saleOrigins: $saleOrigins
      districts: $districts
      productIds: $productIds
      productGroupIds: $productGroupIds
      productGroupCategoryIds: $productGroupCategoryIds
      isProductsSamePurchase: $isProductsSamePurchase
      tagIds: $tagIds
      wasEnriched: $wasEnriched
      averageTicketFrom: $averageTicketFrom
      averageTicketTo: $averageTicketTo
      disregardedIds: $disregardedIds
      page: $page
      maxPerPage: $maxPerPage
      sortBy: $sortBy
      orderBy: $orderBy
    ) {
      data {
        ID: id
        CPF: cpf
        Nome: name
        Telefone: phone
        Email: email
        Total_gasto: totalSpend
        Data_da_ultima_compra: lastPurcharseAt
        Quantidade_de_compras: purcharseCount
      }
    }
  }
`;

export const DASHBOARD_CUSTOMERS_PRODUCT_GROUP_SALES_QUERY = gql`
  query Customers_networkDashboardAllCustomers(
    $businessIds: [Int!]!
    $classification: CustomerClassification
    $isGroupQuery: Boolean
  ) {
    CustomerProfile(businessIds: $businessIds, customerClassification: $classification, isGroupQuery: $isGroupQuery) {
      profileMainGender
      profileMainGenderCount
      profileMainGenderTotalSpent
      profileMainGenderAverageAge
      profileMainGenderRegion
    }
    DashboardProductSales(businessIds: $businessIds, classification: $classification, isGroupQuery: $isGroupQuery) {
      id
      description
      sales_sum
    }
  }
`;

export const UNIT_CUSTOMERS_SECTION_QUERY = gql`
  query CustomerDashboard(
    $businessIds: [Int!]!
    $businessId: Int!
    $classification: CustomerClassification
    $type: TemplateQueryFilterType
  ) {
    ProductGroupSales(
      businessIds: $businessIds
      classification: $classification
      orderBy: "sales_sum"
      sortBy: DESC
      maxPerPage: 3
      page: 1
    ) {
      data {
        id
        sales_sum
        description
      }
    }
    CustomerProfile(businessIds: $businessIds, customerClassification: $classification) {
      profileMainGender
      profileMainGenderCount
      profileMainGenderTotalSpent
      profileMainGenderAverageAge
      profileMainGenderRegion
    }
    TemplateQueryFilters(businessId: $businessId, orderBy: DESC, sortBy: "id", type: $type) {
      data {
        id
        name
        icon
        metadata
        type
      }
    }
    dashboardCustomers(businessIds: $businessIds) {
      all {
        count
        totalSpent
        averageSpent
      }
      new {
        count
        totalSpent
        averageSpent
      }
      lost {
        count
        totalSpent
        averageSpent
      }
      almostLost {
        count
        totalSpent
        averageSpent
      }
      best {
        count
        totalSpent
        averageSpent
      }
      spender {
        count
        totalSpent
        averageSpent
      }
      loyal {
        count
        totalSpent
        averageSpent
      }
    }
    Customers(businessIds: $businessIds, page: 1, maxPerPage: 1) {
      pagination {
        totalItems
      }
    }
  }
`;

export const CUSTOMER_DETAILS_QUERY = gql`
  query CustomerDetails($id: Int, $businessIds: [Int]) {
    Customers(id: $id, businessIds: $businessIds, page: 1, maxPerPage: 1) {
      data {
        id
        cpf
        name
        phone
        email
        gender
        facebook
        instagram
        bornAt
        updatedAt
        instagramFollowers
        purcharseCount
        totalSpend
        averageSpend
        lastPurcharseAt
        busiestWeekday
        whereKnow
        currentAddress {
          id
          postalCode
          city {
            name
            ibge
          }
          street
          number
          district
          complement
          cityName
          uf
        }
      }
    }
  }
`;

export const CUSTOMERS_ENRICHED_QUERY = gql`
  query CustomersEnrichedTable(
    $id: Int
    $cpf: String
    $cpfs: [String]
    $email: String
    $hasValidEmail: Boolean
    $name: String
    $address: String
    $phone: String
    $hasValidPhone: Boolean
    $businessIds: [Int]
    $businessGroupId: Int
    $isOptIn: Boolean
    $isOptInBusiness: Boolean
    $isOptInCustomer: Boolean
    $isOptOut: Boolean
    $dayBirthday: Boolean
    $weekBirthday: Boolean
    $monthBirthday: Boolean
    $birthdayFrom: Date
    $birthdayTo: Date
    $purchaseDateFrom: DateTime
    $purchaseDateTo: DateTime
    $purchaseHourFrom: Time
    $purchaseHourTo: Time
    $purchaseValueFrom: Float
    $purchaseValueTo: Float
    $purchaseCountFrom: Int
    $purchaseCountTo: Int
    $purchaseCountGroupPeriod: Period
    $daysWithoutBuying: Int
    $limitDaysWithoutBuying: Int
    $ageFrom: Int
    $ageTo: Int
    $genders: [Gender]
    $classifications: [CustomerClassification]
    $saleOrigins: [String]
    $districts: [String]
    $productIds: [Int]
    $productGroupIds: [Int]
    $productGroupCategoryIds: [Int]
    $isProductsSamePurchase: Boolean
    $enrichmentConsumeHistoryId: Int
    $tagIds: [Int]
    $wasEnriched: Boolean
    $averageTicketFrom: Float
    $averageTicketTo: Float
    $disregardedIds: [Int]
    $page: Int
    $maxPerPage: Int
    $sortBy: String
    $orderBy: OrderBy
  ) {
    Customers(
      id: $id
      cpf: $cpf
      cpfs: $cpfs
      email: $email
      hasValidEmail: $hasValidEmail
      name: $name
      address: $address
      phone: $phone
      hasValidPhone: $hasValidPhone
      businessIds: $businessIds
      businessGroupId: $businessGroupId
      isOptIn: $isOptIn
      isOptInBusiness: $isOptInBusiness
      isOptInCustomer: $isOptInCustomer
      isOptOut: $isOptOut
      dayBirthday: $dayBirthday
      weekBirthday: $weekBirthday
      monthBirthday: $monthBirthday
      birthdayFrom: $birthdayFrom
      birthdayTo: $birthdayTo
      purchaseDateFrom: $purchaseDateFrom
      purchaseDateTo: $purchaseDateTo
      purchaseHourFrom: $purchaseHourFrom
      purchaseHourTo: $purchaseHourTo
      purchaseValueFrom: $purchaseValueFrom
      purchaseValueTo: $purchaseValueTo
      purchaseCountFrom: $purchaseCountFrom
      purchaseCountTo: $purchaseCountTo
      purchaseCountGroupPeriod: $purchaseCountGroupPeriod
      daysWithoutBuying: $daysWithoutBuying
      limitDaysWithoutBuying: $limitDaysWithoutBuying
      ageFrom: $ageFrom
      ageTo: $ageTo
      genders: $genders
      classifications: $classifications
      saleOrigins: $saleOrigins
      districts: $districts
      productIds: $productIds
      productGroupIds: $productGroupIds
      productGroupCategoryIds: $productGroupCategoryIds
      isProductsSamePurchase: $isProductsSamePurchase
      enrichmentConsumeHistoryId: $enrichmentConsumeHistoryId
      tagIds: $tagIds
      wasEnriched: $wasEnriched
      averageTicketFrom: $averageTicketFrom
      averageTicketTo: $averageTicketTo
      disregardedIds: $disregardedIds
      page: $page
      maxPerPage: $maxPerPage
      sortBy: $sortBy
      orderBy: $orderBy
    ) {
      data {
        id
        name
        phone
        email
        bornAt
      }
      pagination {
        currentPage
        nextPage
        maxPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const DASHBOARD_BUSINESS_GROUP_CUSTOMER_RFV = gql`
  query DashboardBusinessGroupCustomerRFV($groupId: Int, $businessIds: [Int]) {
    DashboardBusinessGroupCustomerRFV(groupId: $groupId, businessIds: $businessIds) {
      status
      total
      classificationTotals {
        total
        classification
        totalSpent
        averageSpent
      }
    }
    RFVClassificationsTracks(groupId: $groupId) {
      classification
      lastPurchase
      purchaseQuantity
      totalAmountSpent
    }
  }
`;

export const RFV_CLASSIFICATIONS_TRACKS = gql`
  query DashboardBusinessGroupCustomerRFV($groupId: Int) {
    RFVClassificationsTracks(groupId: $groupId) {
      classification
      lastPurchase
      purchaseQuantity
      totalAmountSpent
    }
  }
`;

export const CUSTOMER_DASH_TOP_PRODUCTS_QUERY = gql`
  query CustomerDashboardTopProducts($businessIds: [Int!]!, $classification: CustomerClassification) {
    ProductGroupSales(
      businessIds: $businessIds
      classification: $classification
      orderBy: "sales_sum"
      sortBy: DESC
      maxPerPage: 3
      page: 1
    ) {
      data {
        id
        sales_sum
        description
      }
    }
  }
`;

export const CUSTOMER_DASH_WORSE_PRODUCTS_QUERY = gql`
  query CustomerDashboardTopProducts($businessIds: [Int!]!, $classification: CustomerClassification) {
    ProductGroupSales(
      businessIds: $businessIds
      classification: $classification
      orderBy: "sales_sum"
      sortBy: ASC
      maxPerPage: 3
      page: 1
    ) {
      data {
        id
        sales_sum
        description
      }
    }
  }
`;

export const CUSTOMER_DASH_PROFILE_QUERY = gql`
  query CustomerDashboardProfile(
    $businessIds: [Int!]!
    $classification: CustomerClassification
    $isGroupQuery: Boolean
  ) {
    CustomerProfile(businessIds: $businessIds, customerClassification: $classification, isGroupQuery: $isGroupQuery) {
      profileMainGender
      profileMainGenderCount
      profileMainGenderTotalSpent
      profileMainGenderAverageAge
      profileMainGenderRegion
    }
  }
`;

export const CUSTOMER_DASH_CLUSTERS_QUERY = gql`
  query CustomerDashboardClusters($businessIds: [Int!]!) {
    dashboardCustomers(businessIds: $businessIds) {
      all {
        count
        totalSpent
        averageSpent
      }
      new {
        count
        totalSpent
        averageSpent
      }
      lost {
        count
        totalSpent
        averageSpent
      }
      almostLost {
        count
        totalSpent
        averageSpent
      }
      best {
        count
        totalSpent
        averageSpent
      }
      spender {
        count
        totalSpent
        averageSpent
      }
      loyal {
        count
        totalSpent
        averageSpent
      }
    }
  }
`;

export const CUSTOMER_NETWORK_DASH_PRODUCTS_QUERY = gql`
  query CustomerDashboardProductSales(
    $businessIds: [Int!]!
    $classification: CustomerClassification
    $isGroupQuery: Boolean
  ) {
    DashboardProductSales(businessIds: $businessIds, classification: $classification, isGroupQuery: $isGroupQuery) {
      id
      description
      sales_sum
    }
  }
`;

export const PURCHASES_WITH_REGISTRATION_QUERY = gql`
  query PurchasesWithRegistration($businessIds: [Int]!) {
    DashboardNetworkPurchases(businessIds: $businessIds) {
      purchasesWithRegistration {
        percentage
      }
    }
  }
`;

export const CUSTOMER_BY_ID_QUERY = gql`
  query CustomerById($id: Int, $businessIds: [Int]) {
    Customers(id: $id, businessIds: $businessIds) {
      data {
        id
        cpf
        name
        phone
        email
      }
    }
  }
`;
