import "./styles.scss";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

interface IListItemsProps {
  list: { label: string; value: number }[];
  listName?: string;
  itemSelected?: string;
  setItemSelected?: (param?: any, param2?: any) => void;
  listOtherOption?: boolean;
}

function ListItems({
  list,
  listName = "Nome da lista",
  itemSelected = "",
  setItemSelected = () => "",
  listOtherOption = false,
}: IListItemsProps) {
  return (
    <section className="register-modal-list-items-container">
      <p className={`modal-list-name`}>{listName}</p>
      <RadioGroup
        className="modal-list-container"
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={itemSelected}
        onChange={(e) => setItemSelected(e.target.value)}
      >
        {list.map((item: any, index: any) => {
          return (
            <FormControlLabel
              key={`${item.label}-${index}`}
              className="form-list-item"
              id={`form-list-item-${item.label}`}
              value={item.value}
              control={<Radio size="small" />}
              label={item.label}
            />
          );
        })}
        {listOtherOption && (
          <FormControlLabel
            className="form-list-item"
            id={`form-list-item-other-op`}
            value="others"
            control={<Radio size="small" />}
            label="Outros"
          />
        )}
      </RadioGroup>
    </section>
  );
}

export default ListItems;
