import { NETWORK_CUSTOMER } from "./NetworkCustomerReducer";

export const setRfvInfo = (payload: any) => (dispatch: any) => {
  dispatch({
    type: NETWORK_CUSTOMER.RFV_INFO,
    payload,
  });
};

export const setRfvInfoLoading = (payload: any) => (dispatch: any) => {
  dispatch({
    type: NETWORK_CUSTOMER.RFV_INFO_LOADING,
    payload,
  });
};

export const clear = () => (dispatch: any) => {
  dispatch({
    type: NETWORK_CUSTOMER.CLEAR,
  });
};

const functions = {
  setRfvInfo,
  setRfvInfoLoading,
  clear,
};

export default functions;
