import { useEffect, useRef } from "react";
import CSRoute from "./CSRoute";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { useMutation } from "@apollo/client";
import { LinearProgress } from "@mui/material";
import { companySelected } from "@services/auth";
import PrivateAdminRoute from "@routes/PrivateAdminRoute";
import PrivateNetworkRoute from "@routes/PrivateNetworkRoute";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import { LOG_BUSINESS_INTERACTIVITY_MUTATION } from "@mutation/gql/user";
import PopOver from "@genericss/PopOver";
import {
  BbAgroCertificateReportLazy,
  ForgotPasswordV1Lazy,
  Login1Lazy,
  LoginLazy,
  LoginV1Lazy,
  NewPasswordLazy,
  NewPasswordV1Lazy,
  RegisterBusinessV1Lazy,
  RegisterEvaluationUserLazy,
  RegisterLazy,
  RegisterV1Lazy,
  SebraeCertificateReportLazy,
  UserRegisterEmbedLazy,
} from "@pages";
import PrivateNoLayoutRoute from "@routes/PrivateNoLayoutRoute";

export default function Routes() {
  const businessId = companySelected();
  const [logBusinessInteractivity] = useMutation(LOG_BUSINESS_INTERACTIVITY_MUTATION);
  const lastLoggedPath = useRef<string | null>(null);
  const originalPushState = useRef<typeof window.history.pushState>(window.history.pushState);
  useEffect(() => {
    const newPushState: typeof window.history.pushState = async function (
      this: History,
      ...args: Parameters<typeof window.history.pushState>
    ) {
      const ret = originalPushState.current.apply(this, args);
      if (businessId && window.location.pathname !== lastLoggedPath.current) {
        try {
          lastLoggedPath.current = window.location.pathname;
          const logData = {
            businessId,
            message: "Acessando o endereço: " + window.location.pathname,
            section: window.location.pathname.replace("/internal/", ""),
          };
          await logBusinessInteractivity({
            variables: logData,
            fetchPolicy: "no-cache",
          });
        } catch (err) {
          console.error("Erro ao logar interatividade:", err);
        }
      }
      return ret;
    };
    window.history.pushState = newPushState;

    return () => {
      window.history.pushState = originalPushState.current;
    };
  }, []);

  const loadingLinear = (
    <LinearProgress sx={{ backgroundColor: "white", "& .MuiLinearProgress-bar": { backgroundColor: "#C4C4C4" } }} />
  );
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <PublicRoute path="/login/:groupType?" component={LoginLazy} suspenseFallback={loadingLinear} />
        <PublicRoute path="/login1" component={Login1Lazy} suspenseFallback={loadingLinear} />
        <PublicRoute path="/login-v1" component={LoginV1Lazy} suspenseFallback={loadingLinear} />
        <PublicRoute path="/userregister/:groupType?" component={RegisterLazy} suspenseFallback={loadingLinear} />
        <PublicRoute path="/register-v1" component={RegisterV1Lazy} suspenseFallback={loadingLinear} />
        <PublicRoute path="/forgot-password-v1" component={ForgotPasswordV1Lazy} suspenseFallback={loadingLinear} />
        <PublicRoute path="/business-register-v1" component={RegisterBusinessV1Lazy} suspenseFallback={loadingLinear} />
        <PublicRoute path="/new-password-v1/:token" component={NewPasswordV1Lazy} suspenseFallback={loadingLinear} />
        <PublicRoute
          path="/userregister1/:email?/:nome?/:telefone?"
          component={Login1Lazy}
          suspenseFallback={loadingLinear}
        />
        <PublicRoute
          path="/evaluationregister"
          component={RegisterEvaluationUserLazy}
          suspenseFallback={loadingLinear}
        />
        <PublicRoute path="/esqueci-a-senha/:token" component={NewPasswordLazy} suspenseFallback={loadingLinear} />
        <PublicRoute path="/user-register-embed" component={UserRegisterEmbedLazy} suspenseFallback={loadingLinear} />
        <PublicRoute
          path="/validate-sebrae-certificate/:businessId?/:certificateNumber?"
          component={SebraeCertificateReportLazy}
          isAuthenticatedRedirectEnabled={false}
          suspenseFallback={loadingLinear}
        />
        <PublicRoute
          path="/validate-bb-agro-certificate/:businessId?/:certificateNumber?"
          component={BbAgroCertificateReportLazy}
          isAuthenticatedRedirectEnabled={false}
          suspenseFallback={loadingLinear}
        />
        <PrivateNetworkRoute path="/internal/network" />
        <PrivateAdminRoute path="/internal/admin" />
        <PrivateNoLayoutRoute path="/alt" />
        <PrivateRoute path="/internal" />
        <CSRoute path="/cs" />
        <Redirect from="*" to="/login" />
      </Switch>
      {!!businessId && (
        <PopOver requestKey="getCustomersTable" onRedirect={"/internal/customers?recoveryStates=true"} />
      )}
    </BrowserRouter>
  );
}
