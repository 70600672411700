export const internalLink = {
  DEFAULT: "/internal/home",
  DASHBOARD: "/internal/dashboard",
  BUSINESS_REGISTER: "/alt/businessregister",
  GALERY: "/internal/galery",
  ACTIVATION: "/internal/activations",
  INSIGHTS: "/internal/insights",
};

export const external = {
  OFFERS: "/offersCustomer",
  SUCCESS: "/success",
};
