import { internalLink } from "@services/linkHelper";
import { BiudButton } from "@newcomponentes";

function ChooseBizGroupModalFooter() {
  return (
    <BiudButton
      buttonType="primary-outlined"
      size="md"
      id={`btn-choosenetwork-newnetwork`}
      className="business-group-card-button"
      onClick={() => (window.location.href = `${internalLink.BUSINESS_REGISTER}`)}
    >
      Criar empresa em uma nova rede
    </BiudButton>
  );
}

export default ChooseBizGroupModalFooter;
