import { HTMLAttributes } from "react";
import "./styles.css";
import { Close } from "../../CustomIcons";

function ButtonClose({ children, className, ...rest }: HTMLAttributes<HTMLButtonElement>) {
  return (
    <button type="button" className={`btn-close ${className ? className : ""}`} title="Fechar" {...rest}>
      <Close style={{ fontSize: "16px" }} />
      {children}
    </button>
  );
}

export default ButtonClose;
