import "./styles.scss";
import { clsx } from "clsx";
import { IBiudMenuItemProps } from "./types";
import GenSvgIcon from "@generics/GenSvgIcon";
import { KeyboardArrowDown } from "@newcomponentes/CustomIcons";
import { useCallback } from "react";

function BiudMenuItem({
  icon,
  title,
  onClick = () => "",
  haveSubItem = false,
  isOpen = false,
  isSecondary = false,
  isSelected = false,
  isDisabled = false,
  isDisabledSoft = false,
  className,
  isBlackMode,
  ...rest
}: IBiudMenuItemProps) {
  const handleMenuItemClass = useCallback((): string => {
    return clsx(
      "biud-menu-item-container",
      isBlackMode && "biud-menu-item-dark",
      className,
      isOpen && "primary-selected",
      isSecondary && "item-secondary",
      isSecondary && isBlackMode && "sub-dark",
      isBlackMode && haveSubItem && "sub-dark",
      isSelected && "simple-selected",
      isDisabledSoft && "menu-disabled-soft",
      isDisabled && "menu-disabled"
    );
  }, [isOpen, isSecondary, isSelected, isDisabled]);

  return (
    <div className={handleMenuItemClass()} onClick={onClick} title={title} {...rest}>
      <div className="menu-item-info-container">
        {!!icon && (
          <span className="biud-menu-icon">
            <GenSvgIcon type={icon} style={{ fontSize: "16px" }} />
          </span>
        )}
        <span className="biud-menu-title">{title}</span>
      </div>
      {haveSubItem && (
        <div className={`menu-item-status-container arrow-animated  ${isOpen ? "none" : "collapse"}`}>
          <KeyboardArrowDown />
        </div>
      )}
    </div>
  );
}

export type { IBiudMenuItemProps };
export default BiudMenuItem;
