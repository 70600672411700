import {
  CUSTOMER_BY_ID_QUERY,
  CUSTOMER_DASH_CLUSTERS_QUERY,
  CUSTOMER_DASH_PROFILE_QUERY,
  CUSTOMER_DASH_TOP_PRODUCTS_QUERY,
  CUSTOMERS_COMPLEMENTS_QUERY,
  CUSTOMERS_QUERY,
  PURCHASES_WITH_REGISTRATION_QUERY,
} from "@query/gql/customers";

const schema: any = {
  becalls: {
    dashCluster: { resp: "dashboardCustomers", query: CUSTOMER_DASH_CLUSTERS_QUERY },
    byId: { resp: "Customers", query: CUSTOMER_BY_ID_QUERY },
    dashProfile: { resp: "CustomerProfile", query: CUSTOMER_DASH_PROFILE_QUERY },
    dashTopProducts: { resp: "ProductGroupSales", query: CUSTOMER_DASH_TOP_PRODUCTS_QUERY },
    complements: { resp: "Customers", query: CUSTOMERS_COMPLEMENTS_QUERY },
    customers: { resp: "Customers", query: CUSTOMERS_QUERY },
    withRegistration: { resp: "DashboardNetworkPurchases", query: PURCHASES_WITH_REGISTRATION_QUERY },
  },
  getQuery: (key: keyof typeof schema.becalls) => schema.becalls[key].query,
  getResponseKey: (key: keyof typeof schema.becalls) => schema.becalls[key].resp,
};

export default schema;
