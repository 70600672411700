import { ReactNode } from "react";
import "./styles.scss";
import Header from "@components/Header";
import Menu from "@components/Menu";

interface ILayoutContainer {
  children: ReactNode;
}

export default function LayoutContainer({ children = <></> }: ILayoutContainer) {
  return (
    <>
      <div className="flex">
        <Menu />
        <div className="section-content-with-header">
          <Header />
          <main className="anime-fade-in">{children}</main>
        </div>
      </div>
    </>
  );
}
