import { configureStore } from "@reduxjs/toolkit";
import CompanyReducer from "./Company/CompanyReducer";
import CompanyAction from "./Company/CompanyAction";
import PurchaseReducer from "./Purchase/PurchaseReducer";
import PurchaseAction from "./Purchase/PurchaseAction";
import ProductReducer from "./Product/ProductReducer";
import ProductAction from "./Product/ProductAction";
import CurrentUserReducer from "./CurrentUser/CurrentUserReducer";
import CurrentUserAction from "./CurrentUser/CurrentUserAction";
import ActivationAction from "./Activation/ActivationAction";
import ActivationReducer from "./Activation/ActivationReducer";
import RegisterAction from "./Register/RegisterAction";
import RegisterReducer from "./Register/RegisterReducer";
import NetworkDashboardAction from "./NetworkDashboard/NetworkDashboardAction";
import NetworkDashboardReducer from "./NetworkDashboard/NetworkDashboardReducer";
import NetworkCustomerReducer from "@store/NetworkCustomer/NetworkCustomerReducer";
import NetworkCustomerAction from "@store/NetworkCustomer/NetworkCustomerAction";

export const store = configureStore({
  reducer: {
    company: CompanyReducer,
    purchase: PurchaseReducer,
    product: ProductReducer,
    user: CurrentUserReducer,
    activation: ActivationReducer,
    register: RegisterReducer,
    networkDashboard: NetworkDashboardReducer,
    networkCustomer: NetworkCustomerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export const clear = () => (dispatch: any) => {
  dispatch(CompanyAction.clear());
  dispatch(PurchaseAction.clear());
  dispatch(ProductAction.clear());
  dispatch(ActivationAction.clear());
  dispatch(RegisterAction.clear());
  dispatch(NetworkDashboardAction.clear());
  dispatch(NetworkCustomerAction.clear());
};

export const logout = () => (dispatch: any) => {
  dispatch(CompanyAction.logout());
  dispatch(PurchaseAction.logout());
  dispatch(ProductAction.logout());
  dispatch(CurrentUserAction.logout());
};
