import { HTMLAttributes, Ref } from "react";
import "./styles.scss";
import { clsx } from "clsx";

export interface IMenuRootProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  ref?: Ref<HTMLDivElement>;
}

function MenuItemContainer({ className, ref, ...rest }: IMenuRootProps) {
  return <nav className={clsx("menu-navigation-item-container", className)} ref={ref} {...rest} />;
}

export default MenuItemContainer;
