import { Helmet } from "react-helmet";

export default function MetaPixelCodeScript() {
  const tokens: Record<string, string> = {
    prod: "732209203839281",
    "sebrae-prod": "732209203839281",
  };

  const env = import.meta.env.VITE_ENV as "prod" | "sebrae-prod" | "hmg" | "dev";
  const metaToken = tokens[env];

  if (!metaToken) {
    return <Helmet />;
  }

  return (
    <Helmet>
      <script type="text/javascript">
        {`!(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
          })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
        fbq("init", "${metaToken}");
        fbq("track", "PageView");`}
      </script>
    </Helmet>
  );
}
