import biudApi from "@services/apis/biud/api";

export const getUserInfo = async ({ token }: { token: string }) => {
  if (!token) {
    throw new Error("Token not found");
  }

  const res = await biudApi.get(`/get-user-info`, { headers: { Authorization: `Bearer ${token}` } });
  return res.data;
};
