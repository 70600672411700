import { keycloak_config } from '../constants'
import Keycloak from 'keycloak-js'

const keycloak = new Keycloak({
    url: keycloak_config.keycloakUrl,
    realm: keycloak_config.realm,
    clientId: keycloak_config.clientId,
})

export default keycloak
