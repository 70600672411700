import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import moment from "moment";
import { currency } from "@services/utils";
import Skeleton from "react-loading-skeleton";
import { EyeSlash } from "../../CustomIcons";
import { useLazyQuery } from "@apollo/client";
import { getBusinessInsights } from "@query/insight";
import { INSIGHT_PRODUCT_LIST_QUERY } from "@query/gql/insight";
import { getBusinessIds, isComercial } from "@services/auth";
import { CACHE_TIME, getFetchPolicyForKey } from "@/hooks/useCacheWithExpiration";
import GenSvgIcon from "@generics/GenSvgIcon"

export default function RankingCard({
  rank,
  title,
  value,
  showSeeDetailsBtn,
  itemId,
  businessIds = [],
  loading,
  history = [],
  loadingProductId,
  loadingPredictProductSales,
  searchDays,
  valueVisible,
  setValueVisible,
  isProductInsight = true,
}: any) {
  const [getProductInsight, { loading: loadingProductInsight }] = useLazyQuery(INSIGHT_PRODUCT_LIST_QUERY);

  const [insights, setInsights] = useState([]);
  const rankColor = [1, 2, 3].includes(parseInt(rank)) ? "top3" : "last3";

  const fetchInsights = async (signal: AbortSignal) => {
    try {
      let insightsList = [];

      if (isProductInsight) {
        const insightsIds = [33, 34];

        const promises = insightsIds.map((insightId) => {
          const data = {
            businessIds: getBusinessIds(),
            productIds: [itemId],
            endDate: moment().format("YYYY-MM-DD"),
            insightId,
          };

          return getProductInsight({ variables: data, context: { fetchOptions: { signal } } });
        });

        insightsList = (await Promise.all(promises))
          .filter((item) => item.data?.InsightProductList?.[0])
          .map((item) => {
            return item.data.InsightProductList[0] || "";
          });
      } else {
        const data = { businessId: itemId };
        const cache = getFetchPolicyForKey(`network-sales-ranking-biz-insight-${itemId}`, CACHE_TIME.EXTRA_LONG, data);
        const res = await getBusinessInsights(data, cache);
        insightsList = res.BusinessInsight.map((i: any) => ({
          insight: i.title + " " + i.description,
        }));
      }

      setInsights(insightsList);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (isProductInsight) fetchInsights(signal); //Apenas insight de produtos para economizar requisições já que a maioria dos business não tem insight.

    return () => {
      controller.abort();
    };
  }, []);

  return loading || loadingProductId || loadingPredictProductSales ? (
    <Skeleton width={"100%"} height={95} />
  ) : (
    <div className="ranking-card anime-fade-in">
      <header className="card-header">
        <div className="card-title">
          <div className={`card-rank ${rankColor}`}>{`${rank}°`}</div>
          <span className={`card-title-business`}>
            {isComercial() && title.includes("Drogafuji")
              ? "Drogaria BIUD"
              : isComercial() && title.includes("Ricco")
                ? "Hamburgueria BIUD"
                : title || ""}
          </span>

          {showSeeDetailsBtn && (
            <button type="button" className="see-details-btn" onClick={() => setValueVisible((old: boolean) => !old)}>
              {!valueVisible ? <EyeSlash style={{ fontSize: "14px" }} /> : <GenSvgIcon type="EyeOutlined" />}
            </button>
          )}
        </div>

        <div className="card-value">
          <span>
            {valueVisible ? (
              <section className="anime-scale-in-center">{currency(value)}</section>
            ) : (
              <section className="anime-slit-in-hr">
                &#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;
              </section>
            )}
          </span>
          {/*{history.length > 0 &&*/}
          {/*  <span className="graph2">*/}
          {/*<SparklineApex*/}
          {/*  name={'Vendas'}*/}
          {/*  data={generateArrayDataForPredictChart(history, searchDays)}*/}
          {/*  categories={generateArrayCategoriesForPredictChart(history, searchDays)}*/}
          {/*  width={'100%'}*/}
          {/*  height={70}*/}
          {/*  isMonetary={true}*/}
          {/*  filterSelected={searchDays}*/}
          {/*/>*/}
          {/*</span>*/}
          {/*}*/}
          {/*    {history && history[0]?.support !== undefined &&*/}
          {/*      <span style={{*/}
          {/*        alignSelf: "end"*/}
          {/*      }}>*/}
          {/*  <Tooltip title={history[0]?.support} placement="top" arrow>*/}
          {/* <img id="mia-explain" src={miaExplain} alt="Mia Explica" className="mia-explain-img-info"/>*/}
          {/*    </Tooltip>*/}
          {/*</span>*/}
          {/*    }*/}
        </div>
      </header>

      <div className="card-content">
        <div className="product-insight">
          {insights.length
            ? insights.map((i: any, index) => <p key={index}>{i.insight}</p>)
            : "Sem insights no momento"}
        </div>
        {/* <ActivationButton ranking /> */}
      </div>
    </div>
  );
}

RankingCard.propTypes = {
  rank: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
