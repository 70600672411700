import { TBrand } from "@components/BiudBrands";

export type TBrandsSchemaKeyValue = { name: TBrand; width?: string; height?: string };

export const brandsSchema = {
  biudHeader: { name: "biud-short", width: "32px", height: "32px" } as TBrandsSchemaKeyValue,
  genHeader: { name: "gen", width: "53px", height: "32px" } as TBrandsSchemaKeyValue,
  sebraeHeader: { name: "sebrae", width: "72px", height: "40px" } as TBrandsSchemaKeyValue,
  biudMenu: { name: "biud-full" } as TBrandsSchemaKeyValue,
  genMenu: { name: "gen" } as TBrandsSchemaKeyValue,
  genWhiteMenu: { name: "gen-white" } as TBrandsSchemaKeyValue,
  sebraeMenu: { name: "sebrae" } as TBrandsSchemaKeyValue,
  biudLoading: { name: "biud-short" } as TBrandsSchemaKeyValue,
  genLoading: { name: "gen" } as TBrandsSchemaKeyValue,
  sebraeLoading: { name: "sebrae" } as TBrandsSchemaKeyValue,
  biudLogin: { name: "biud-short", width: "6em", height: "2.2em" } as TBrandsSchemaKeyValue,
  genLogin: { name: "gen", width: "87.11px", height: "2.2em" } as TBrandsSchemaKeyValue,
  sebraeLogin: { name: "sebrae", width: "120px" } as TBrandsSchemaKeyValue,
};
