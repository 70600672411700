import { Helmet } from "react-helmet";

export default function HotJarScript() {
  const tokens: Record<string, { hjid: number; hjsv: number }> = {
    prod: { hjid: 3178421, hjsv: 6 },
    "sebrae-prod": { hjid: 3178421, hjsv: 6 },
  };

  const env = import.meta.env.VITE_ENV as "prod" | "sebrae-prod" | "hmg" | "dev";
  const hotjarConfig = tokens[env];

  if (!hotjarConfig?.hjid) {
    return <Helmet />;
  }

  return (
    <Helmet>
      <script type="text/javascript">
        {`(function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: ${hotjarConfig.hjid}, hjsv: ${hotjarConfig.hjsv} };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");`}
      </script>
    </Helmet>
  );
}
