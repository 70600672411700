import { clsx } from "clsx";
import "./styles.scss";
import { IBiudInput } from "./types";

function BiudInput({
  labelBefore = "",
  labelAfter = "",
  className = "",
  type = "text",
  isValid = false,
  isInvalid = false,
  disabled = false,
  invalidMsg = "",
  ...rest
}: IBiudInput) {
  return (
    <label className={clsx("biud-input-label-container", isInvalid && "color-error", disabled && "color-disabled")}>
      {labelBefore}
      <input
        type={type}
        className={clsx("biud-input", isInvalid && "input-required-invalid", isValid && "input-valid", className)}
        disabled={disabled}
        {...rest}
      />
      {isInvalid && <span>{invalidMsg}</span>}
      {labelAfter}
    </label>
  );
}

export type { IBiudInput };
export default BiudInput;
