import { getUserEsg, isAdmin } from "@services/auth";
import { isGsGroup } from "@services/utils";

export const menuScheme = {
  businessMenu: [
    {
      name: "Inicio",
      icon: "HomeAlt",
      url: "/internal/home",
      isEnabled: true,
      isAdminOnly: false,
      submenus: [],
    },
    {
      name: "Hot Leads",
      icon: "Fire",
      url: "/internal/hotleads",
      isEnabled: isGsGroup(),
      isAdminOnly: false,
      submenus: [],
    },
    {
      name: "Clientes",
      icon: "Users",
      url: "customers",
      isEnabled: true,
      isAdminOnly: false,
      submenus: [
        {
          name: "Clientes",
          url: "/internal/customers",
          isEnabled: true,
          search: undefined,
        },
        {
          name: "Listas Inteligentes",
          url: "/internal/insights",
          isEnabled: true,
          search: undefined,
        },
      ],
    },
    {
      name: "Produtos",
      icon: "ShoppingBag",
      url: "/internal/products",
      isEnabled: true,
      isAdminOnly: false,
      submenus: [],
    },
    {
      name: "Vendas",
      icon: "DollarSign",
      url: "/internal/sales",
      isEnabled: true,
      isAdminOnly: false,
      submenus: [],
    },
    {
      name: "Ativações",
      icon: "Bolt",
      url: "/internal/activations",
      isEnabled: true,
      isAdminOnly: false,
      submenus: [],
    },
    {
      name: "ESG",
      icon: "Globe",
      url: "/internal/esg",
      isEnabled: getUserEsg(),
      isAdminOnly: false,
      submenus: [],
    },
    {
      name: "Ofertas",
      icon: "Percentage",
      url: "/internal/offers",
      isEnabled: true,
      isAdminOnly: false,
      submenus: [],
    },
    {
      name: "Relatórios",
      icon: "FileCopyAlt",
      url: "/internal/reports",
      isEnabled: true,
      isAdminOnly: false,
      submenus: [],
    },
    {
      name: "Financeiro",
      icon: "CreditCard",
      url: "/internal/billing",
      isEnabled: true,
      isAdminOnly: false,
      submenus: [],
    },
    {
      name: "Configurações",
      icon: "Config",
      url: "configurations",
      isEnabled: true,
      submenus: [
        {
          name: "Integrações",
          url: "/internal/data",
          isEnabled: true,
          search: "tab=1",
        },
        {
          name: "Canais de Disparo",
          url: "/internal/integrations",
          isEnabled: true,
          search: "tab=8",
        },
        {
          name: "Landing Pages",
          url: "/internal/landing-pages",
          isEnabled: true,
          search: "tab=4",
        },
        {
          name: "Controle de Spam",
          url: "/internal/noise-control",
          isEnabled: true,
          search: "tab=1",
        },
        {
          name: "Configurações",
          url: "/internal/configurations",
          isEnabled: true,
          search: "tab=1",
        },
      ],
    },
    {
      name: "Administração",
      icon: "UserMd",
      url: "/internal/admin",
      isEnabled: isAdmin(),
      submenus: [],
    },
    {
      name: "Redes Sociais",
      icon: "Tablet",
      url: "/internal/socialmedia",
      isEnabled: isAdmin(),
      isAdminOnly: true,
      submenus: [],
    },
  ],
  networkMenu: [
    {
      name: "Inicio",
      icon: "HomeAlt",
      url: "home",
      isEnabled: true,
      submenus: [],
    },
    {
      name: "General",
      icon: "Config",
      url: "general",
      isEnabled: isGsGroup(),
      submenus: [
        {
          name: "Dashboard",
          url: "general-dashboard",
          isEnabled: true,
          search: undefined,
        },
        {
          name: "Home lite",
          url: "general-lite",
          isEnabled: true,
          search: undefined,
        },
        {
          name: "Wi-fi",
          url: "general-wifi",
          isEnabled: true,
          search: "tab=4",
        },
        {
          name: "Hotleads",
          url: "hotleads",
          isEnabled: true,
          search: "tab=3",
        },
      ],
    },
    {
      name: "Dashboard",
      icon: "Graphics",
      url: "dashboard",
      isEnabled: true,
      submenus: [],
    },
    {
      name: "Clientes",
      icon: "Users",
      url: "customers",
      isEnabled: true,
      submenus: [
        {
          name: "Clientes",
          url: "customers-list",
          isEnabled: true,
          search: undefined,
        },
        {
          name: "Descobrir",
          url: "findout",
          isEnabled: true,
          search: undefined,
        },
      ],
    },
    {
      name: "Vendas",
      icon: "DollarSign",
      url: "sales-list",
      isEnabled: true,
      submenus: [],
    },
    {
      name: "Produtos",
      icon: "ShoppingBag",
      url: "products",
      isEnabled: true,
      submenus: [],
    },
    {
      name: "Ativações",
      icon: "Bolt",
      url: "activations",
      isEnabled: true,
      submenus: [],
    },
    {
      name: "Analytics",
      icon: "ChartPieAlt",
      url: "analytics",
      isEnabled: true,
      isAdminOnly: false,
      submenus: [],
    },
    {
      name: "Relatórios",
      icon: "FileCopyAlt",
      url: "reports",
      isEnabled: true,
      isAdminOnly: false,
      submenus: [],
    },

    {
      name: "Financeiro",
      icon: "CreditCard",
      url: "billing",
      isEnabled: true,
      isAdminOnly: false,
      submenus: [],
    },
    {
      name: "Configurações",
      icon: "Config",
      url: "configurations",
      isEnabled: true,
      submenus: [
        {
          name: "Canais de Disparo",
          url: "integrations",
          isEnabled: true,
          search: "tab=1",
        },
        {
          name: "Configurações",
          url: "configurations",
          isEnabled: true,
          search: "tab=1",
        },
      ],
    },
  ],
};

export type TMenuScheme = {
  name: string;
  icon: string;
  url: string;
  isEnabled: boolean;
  isAdminOnly?: boolean;
  submenus: {
    name: string;
    url: string;
    isEnabled: boolean;
    search?: string | undefined;
  }[];
};
