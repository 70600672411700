import { REGISTER } from "./RegisterReducer";

export const setEmail = (payload: any) => (dispatch: any) => {
  dispatch({
    type: REGISTER.EMAIL,
    payload,
  });
};

export const setName = (payload: any) => (dispatch: any) => {
  dispatch({
    type: REGISTER.NAME,
    payload,
  });
};

export const setPhone = (payload: any) => (dispatch: any) => {
  dispatch({
    type: REGISTER.PHONE,
    payload,
  });
};

export const setPassword = (payload: any) => (dispatch: any) => {
  dispatch({
    type: REGISTER.PASSWORD,
    payload,
  });
};

export const setBusinessName = (payload: any) => (dispatch: any) => {
  dispatch({
    type: REGISTER.BUSINESS_NAME,
    payload,
  });
};

export const setBusinessCity = (payload: any) => (dispatch: any) => {
  dispatch({
    type: REGISTER.BUSINESS_CITY,
    payload,
  });
};

export const setBusinessPosition = (payload: any) => (dispatch: any) => {
  dispatch({
    type: REGISTER.BUSINESS_POSITION,
    payload,
  });
};

export const setBusinessUnitsAmount = (payload: any) => (dispatch: any) => {
  dispatch({
    type: REGISTER.BUSINESS_UNITS_AMOUNT,
    payload,
  });
};

export const setBusinessSegment = (payload: any) => (dispatch: any) => {
  dispatch({
    type: REGISTER.BUSINESS_SEGMENT,
    payload,
  });
};

export const clear = () => (dispatch: any) => {
  dispatch({
    type: REGISTER.CLEAR,
  });
};

const functions = {
  setEmail,
  setName,
  setPhone,
  setPassword,
  setBusinessName,
  setBusinessCity,
  setBusinessPosition,
  setBusinessUnitsAmount,
  setBusinessSegment,
  clear,
};

export default functions;
