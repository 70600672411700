import { useHistory } from "react-router-dom";
import { LinearProgress, Box, Typography } from "@mui/material";
import BiudButton from "@newcomponentes/Buttons/BiudButton";
import { useGlobalData } from "@context/GlobalContext";
import GenSvgIcon from "@generics/GenSvgIcon";
import { SchemaInter } from "./interfaces";
import schema from "./data";

interface PopOverProps {
  requestKey: string;
  onRedirect?: string;
}

function PopOver({ requestKey, onRedirect }: PopOverProps) {
  const { requestIndicators, setRequestIndicators } = useGlobalData();
  const history = useHistory();
  const indicator = requestIndicators?.[requestKey];

  if (!indicator || !indicator?.visible || !schema?.[indicator?.status]) return null;

  const handleClose = () => {
    setRequestIndicators((prev: any) => ({
      ...prev,
      [requestKey]: { ...prev[requestKey], visible: false },
    }));
  };

  const handleClickVerResultado = () => {
    handleClose();
    if (onRedirect) {
      history.push(onRedirect);
    }
  };

  const schemaItem = schema[indicator.status];
  const { title, text, resultBtn, closeBtn, isLoading } = schemaItem as SchemaInter;
  return (
    <Box
      sx={{
        bgcolor: "#E6F8EC",
        borderRadius: 3,
        padding: "24px",
        boxShadow: "0px 2px 6px #B3EECB",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        position: "fixed",
        bottom: "30px",
        left: "30px",
        zIndex: 2,
        maxWidth: 600,
        "@media (max-width: 360px)": {
          bottom: "15px",
          left: "15px",
          right: "15px",
          padding: "16px",
          maxWidth: "unset",
          width: "auto",
        },
      }}
    >
      {title && (
        <Typography variant="h6" sx={{ fontWeight: 700, color: "#000" }}>
          {title}
        </Typography>
      )}
      {text && <Typography>{text}</Typography>}
      {(closeBtn || resultBtn) && (
        <Box
          display="flex"
          flexDirection="row"
          gap={2}
          mt={1}
          alignItems="center"
          sx={{
            "@media (max-width: 410px)": {
              flexDirection: "column",
              width: "100%",
            },
          }}
        >
          {closeBtn && (
            <BiudButton buttonType="text-secondary" size="sm" onClick={handleClose}>
              {closeBtn}
            </BiudButton>
          )}
          {resultBtn && (
            <BiudButton buttonType="primary" size="sm" onClick={handleClickVerResultado}>
              {resultBtn}
              <GenSvgIcon type="ArrowRight" />
            </BiudButton>
          )}
        </Box>
      )}
      {isLoading && (
        <Box width="100%" mt={2}>
          <LinearProgress sx={{ borderRadius: "4px" }} />
        </Box>
      )}
    </Box>
  );
}
export default PopOver;
