import { HTMLAttributes } from "react";
import "./styles.scss";

interface IStepsProgressBarProps {
  stepsQnt: number;
  currentStep: number;
  containerProps?: HTMLAttributes<HTMLDivElement>;
}

function StepsProgressBar({ stepsQnt, currentStep, containerProps = {} }: IStepsProgressBarProps) {
  return (
    <section className={`steps-progress-bar-container`} {...containerProps}>
      {new Array(stepsQnt).fill(0).map((_, index) => {
        return (
          <div key={`steps-${index}`} className={`progress-bar-step ${index <= currentStep - 1 ? "active-bar" : ""}`} />
        );
      })}
    </section>
  );
}

export default StepsProgressBar;
