import { FC } from "react";
import { Suspense } from "react";
import {
  AdminActivationLazy,
  AdminBusinessLazy,
  AdminLazy,
  AdminPlansLazy,
  EnrichmentAdminLazy,
  HotleadsAdminLazy,
  LoadingPage,
} from "@pages";
import { useSelector } from "react-redux";
import ProtectedRoute from "@routes/ProtectedRoute";
import { ICompanyStore } from "@store/Company/CompanyReducer";
import { isAuthenticated, isFinancialUser } from "@services/auth";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import RouteLoadingPage from "@pages/RouteLoadingPage/RouteLoadingPage";
import LayoutContainer from "@containers/LayoutContainer/LayoutContainer";

interface IPrivateRouteProps extends RouteProps {
  component?: FC<any>;
  path: string;
}

function PrivateRoute({ ...props }: IPrivateRouteProps) {
  const loadingGlobal = useSelector((store: ICompanyStore) => store.company.loading);

  const isFinancialOrAdminUser = isFinancialUser();

  return (
    <LayoutContainer>
      <Suspense fallback={<RouteLoadingPage />}>
        <Route
          {...props}
          render={({ location, match: { url } }) => {
            if (!isAuthenticated || !isFinancialOrAdminUser) {
              return <Redirect from="*" to="/login" />;
            }

            if (loadingGlobal) {
              return <Route path={`${url}/*`} component={LoadingPage} />;
            }

            return (
              <Switch>
                <ProtectedRoute
                  path={`${url}/`}
                  component={AdminLazy}
                  exact
                  location={location}
                  isPaymentOk={true}
                  isEnabled={isFinancialOrAdminUser}
                />
                <ProtectedRoute
                  path={`${url}/enrichment`}
                  component={EnrichmentAdminLazy}
                  location={location}
                  isPaymentOk={true}
                  isEnabled={isFinancialOrAdminUser}
                />
                <ProtectedRoute
                  path={`${url}/activation-adm`}
                  component={AdminActivationLazy}
                  location={location}
                  isPaymentOk={true}
                  isEnabled={isFinancialOrAdminUser}
                />
                <ProtectedRoute
                  path={`${url}/plans`}
                  component={AdminPlansLazy}
                  location={location}
                  isPaymentOk={true}
                  isEnabled={isFinancialOrAdminUser}
                />
                <ProtectedRoute
                  path={`${url}/activation-requests`}
                  component={HotleadsAdminLazy}
                  location={location}
                  isPaymentOk={true}
                  isEnabled={isFinancialOrAdminUser}
                />
                <ProtectedRoute
                  path={`${url}/business`}
                  component={AdminBusinessLazy}
                  location={location}
                  isPaymentOk={true}
                  isEnabled={isFinancialOrAdminUser}
                />
                <Redirect from={`${url}/*`} to={`${url}/`} />
                <Redirect from={`${url}`} to={`${url}/`} />
              </Switch>
            );
          }}
        />
      </Suspense>
    </LayoutContainer>
  );
}

export default PrivateRoute;
