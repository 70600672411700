import "./styles.scss";
import { clsx } from "clsx";
import BiudAvatar from "@newcomponentes/BiudAvatar";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ArrowRight2 from "@newcomponentes/CustomIcons/Arrows/ArrowRight2";
import { IBiudNavItemProps } from "@newcomponentes/BiudNavItem/types";
import GenSvgIcon from "@generics/GenSvgIcon";

function BiudNavItem({
  avatarImgSrc,
  title = "",
  description = "",
  titleProps = {},
  descriptionProps = {},
  className = "",
  icon = "",
  ...rest
}: IBiudNavItemProps) {
  const titleClassName = titleProps?.className;
  const descriptionClassName = descriptionProps?.className;

  delete titleProps.className;
  delete descriptionProps.className;

  return (
    <li className={clsx("biud-nav-item", className)} {...rest}>
      <div className="biud-nav-item-avatar-container">
        {!avatarImgSrc ? (
          <BiudAvatar name={title} className="biud-nav-avatar" />
        ) : (
          <LazyLoadImage src={avatarImgSrc} alt="avatar" className="biud-nav-item-img" />
        )}
      </div>
      <div className="biud-nav-item-info-container" title={title}>
        <span className={clsx("biud-nav-item-info-name", titleClassName)} {...titleProps}>
          {title}
        </span>
        <span className={clsx("biud-nav-item-info-qnt-units", descriptionClassName)} {...descriptionProps}>
          {description}
        </span>
      </div>
      <div className="biud-nav-item-arrow-container">
        {!icon ? <ArrowRight2 /> : <GenSvgIcon type={icon} style={{ fontSize: "24px" }} />}
      </div>
    </li>
  );
}

export type { IBiudNavItemProps };
export default BiudNavItem;
