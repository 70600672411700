import { useLayoutEffect, useState } from "react";
import "./styles.scss";
import { getCities } from "@query/city";
import { func, object, string } from "prop-types";
import { Autocomplete } from "@mui/material";
import { removeAccent } from "@services/utils";

function BiudCityInput({ labelBefore, labelAfter, value, setCityName }: any) {
  const [cities, setCities] = useState([]);
  const fetchCities = async (name: any) => {
    try {
      const res = await getCities({ name: name || "" });
      setCities(
        res.Cities.map((city: any) => ({
          name: `${city.name} - ${city.uf}`,
          ibge: city.ibge,
        }))
      );
    } catch (err) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      console.log(err.message);
    }
  };

  useLayoutEffect(() => {
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
    fetchCities();
  }, []);

  return (
    <Autocomplete
      options={cities}
      selectOnFocus
      autoHighlight
      clearOnBlur
      noOptionsText={"Nada encontrado"}
      getOptionLabel={(option) => option.name || ""}
      filterOptions={(options, state) => {
        if (!state.inputValue) return options.slice(0, 50);
        return options.filter((op) =>
          removeAccent(op.name.toLowerCase()).includes(removeAccent(state.inputValue.toLowerCase()))
        );
      }}
      value={value}
      onChange={(event, newValue) => {
        setCityName(newValue);
      }}
      // @ts-expect-error TS(2339): Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
      onInputChange={(e) => fetchCities(e.target.value)}
      renderInput={(params) => (
        <div className={`biud-register-autocomplete-city`} ref={params.InputProps.ref} style={{ width: "100%" }}>
          <label className="biud-register-city-autocomplete-label">
            {labelBefore}
            <input type="text" placeholder="Comece a digitar" {...params.inputProps} />
            {labelAfter}
          </label>
        </div>
      )}
    />
  );
}

BiudCityInput.propTypes = {
  labelBefore: string,
  labelAfter: string,
  value: object.isRequired,
  setCityName: func.isRequired,
};

BiudCityInput.defaultProps = {
  labelBefore: "",
  labelAfter: "",
};

export default BiudCityInput;
