import { NETWORK_DASHBOARD } from "@store/NetworkDashboard/NetworkDashboardReducer";

export const setGeneralSelectShopLoading = (payload: any) => (dispatch: any) => {
  dispatch({
    type: NETWORK_DASHBOARD.LOADING_SHOP_SELECT,
    payload,
  });
};

export const clear = () => (dispatch: any) => {
  dispatch({
    type: NETWORK_DASHBOARD.CLEAR,
  });
};

const functions = {
  setGeneralSelectShopLoading,
  clear,
};

export default functions;
