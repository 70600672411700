import "./styles.scss";
import Version from "@components/Version/Version";
import { Globe } from "@newcomponentes/CustomIcons";
import BiudLogoFull from "@newcomponentes/CustomIcons/Generics/BiudLogoFull";
import { getIsUserEsgAdminNacional, getIsUserEsgAdminRegional } from "@services/auth";

function EsgAdminMenu() {
  const isRegional = getIsUserEsgAdminRegional();
  const isNacional = getIsUserEsgAdminNacional();

  return (
    <div className="esg-menu">
      <a id="esg-admin" title="ESG Admin" href="/internal/esg-admin">
        <div className="item item-centralizado menu-name menu-item-selected">
          <Globe style={{ fontSize: 14, marginTop: -1 }} />
          {isRegional ? "Observatório Estadual" : "Observatório Nacional"}
        </div>
      </a>
      <div className="version-container">
        <BiudLogoFull viewBox="0 0 97 32" style={{ color: "#D81656", width: "97px", height: "32px" }} />
        <Version />
      </div>
    </div>
  );
}

export default EsgAdminMenu;
