import { useState, useEffect } from "react";
import keycloak from "@/auth/keycloak";

function useKeycloak() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [loadingAuth, setLoadingAuth] = useState(true);

  useEffect(() => {
    async function initAuth() {
      if (keycloak?.didInitialize) {
        setIsAuthenticated(keycloak.authenticated);
        setToken(keycloak.token);
        setLoadingAuth(false);
        return;
      }

      try {
        const authenticated: boolean = await keycloak?.init({
          onLoad: "check-sso",
          checkLoginIframe: true,
        });

        setIsAuthenticated(authenticated);
        setToken(authenticated ? keycloak.token : null);
      } catch (error) {
        console.error("Erro ao iniciar o Keycloak", error);
      } finally {
        setLoadingAuth(false);
      }
    }
    initAuth();
  }, []);

  async function keyLogin() {
    try {
      await keycloak.login();
      setIsAuthenticated(true);
      setToken(keycloak.token);
    } catch (error) {
      console.error("Erro ao fazer login no Keycloak", error);
    }
  }

  async function logout() {
    try {
      await keycloak.logout();
      setIsAuthenticated(false);
      setToken(null);
    } catch (error) {
      console.error("Erro ao fazer logout no Keycloak", error);
    }
  }

  return { logout, keyLogin, token, loadingAuth, isAuthenticated };
}

export default useKeycloak;
