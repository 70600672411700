import { LOGIN_SOCIAL } from "@/constants";
import { login, setUser } from "@services/auth";
import { getUserInfo } from "@services/apis/biud/fetchs";
import biudApi from "@services/apis/biud/api";

export const tryToLogin = async (isAuthenticated: boolean, token: string) => {
  if (!isAuthenticated || !token) throw new Error("Usuário não autenticado ou token inválido.");
  try {
    const res = await biudApi.post(LOGIN_SOCIAL, {
      access_token: token,
      type: "amei",
    });
    const data = await res?.data;
    const userInfo = await getUserInfo({ token: data?.token });
    login(data?.token);
    return setUser(userInfo);
  } catch (error) {
    console.error("Erro ao chamar o backend:", error);
    throw new Error("Erro no login social");
  }
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
export const filterProps = (list: string[], commonProps = {}) => {
  return (list || [])
    ?.map((ele) => ({ [ele]: commonProps?.[ele as keyof typeof commonProps] }))
    ?.filter((obj) => obj)
    ?.reduce((acc, val) => ({ ...acc, ...val }), {});
};

export const simpleHash = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    // Força ficar dentro de 32 bits:
    hash |= 0;
  }
  // Convertemos para base 16 só pra ficar "mais amigável"
  return Math.abs(hash).toString(16);
};
