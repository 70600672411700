export const handleSize = (size: string) => {
  const sizeMap = new Map([
    ["xs", 16],
    ["sm", 24],
    ["md", 32],
    ["lg", 40],
  ]);
  return sizeMap.get(size) || 32;
};

export const handleFontSize = (size: string) => {
  const sizeMap = new Map([
    ["xs", "8px"],
    ["sm", "12px"],
    ["md", "16px"],
    ["lg", "18px"],
  ]);
  return sizeMap.get(size) || "16px";
};
