import { useMemo } from "react";
import { IBiudBrandsProps, TBrand, IBrandInfo } from "./types";
import { brandBiudShort, brandBiudFull, brandSebrae, genLogo, genLogoBranco } from "@imgs";

function BiudBrands({ brand, width, height, ...rest }: IBiudBrandsProps) {
  const getBrandSrc = (brand: TBrand): IBrandInfo => {
    const brandsMap = new Map<TBrand, IBrandInfo>([
      ["biud-short", { src: brandBiudShort, alt: "Logo biud" }],
      ["biud-full", { src: brandBiudFull, width: "97px", alt: "Logo biud" }],
      ["sebrae", { src: brandSebrae, alt: "Logo sebrae" }],
      ["gen", { src: genLogo, width: "97px", alt: "Logo da General Shopping" }],
      ["gen-white", { src: genLogoBranco, width: "97px", alt: "Logo da General Shopping" }],
    ]);
    return brandsMap.get(brand) || { src: brandBiudShort, alt: "Logo biud" };
  };

  const brandInfo: IBrandInfo = useMemo(() => getBrandSrc(brand), [brand]);

  return (
    <img
      alt="brand"
      src={brandInfo.src}
      {...rest}
      width={width || brandInfo.width}
      height={height || brandInfo.height}
    />
  );
}

export type { IBiudBrandsProps, TBrand, IBrandInfo };
export default BiudBrands;
