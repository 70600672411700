import ClarityScript from "./ClarityScript";
import GoogleTagManagerScript from "./GoogleTagManagerScript";
import MetaPixelCodeScript from "@genericss/Scripts/MetaPixelCodeScript";
import ActiveCampaignScript from "@genericss/Scripts/ActiveCampaignScript";
import HotJarScript from "@genericss/Scripts/HotJarScript";

export default function AnalyticScripts() {
  return (
    <>
      <ClarityScript />
      <GoogleTagManagerScript />
      <MetaPixelCodeScript />
      <ActiveCampaignScript />
      <HotJarScript />
    </>
  );
}
