import schema from "./iconScheme";
import { SvgIcon, SvgIconProps } from "@mui/material";
import { GenSvgIconProps, TypeSchema } from "./types";

function GenSvgIcon({ type, ...rest }: GenSvgIconProps) {
  if (!type || !schema?.[type]) return null;

  const iconData = schema[type as keyof typeof schema] as Partial<{
    d: string;
    newProps?: SvgIconProps;
  }>;
  const { newProps, d, ...restProps } = iconData || {};
  if (!d) return null;
  return (
    <SvgIcon {...rest} {...(newProps || {})}>
      <path d={d} {...restProps} />
    </SvgIcon>
  );
}

export type { GenSvgIconProps, TypeSchema };
export default GenSvgIcon;
