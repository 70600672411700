import { isSebrae } from "@services/auth";
import { useEffect, useState, SyntheticEvent } from "react";
import { changeRootTheme, getDefaultTheme } from "@/themes/utils";
import { Alert, Snackbar, SnackbarCloseReason } from "@mui/material";

export default function Version() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [actionCounter, setActionCounter] = useState(0);

  const version = import.meta.env.VITE_VERSION ?? [];
  const versionNumber =
    version.includes("release") || version.includes("staging") ? version.replace(/[^\d.]/g, "") : version;

  const handleClose = (event: SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (actionCounter === 10) {
      const currentTheme = getDefaultTheme();
      const isDark = currentTheme.includes("dark");

      if (isSebrae()) {
        changeRootTheme(isDark ? "sebrae" : "biud-dark");
        setMessage(!isDark ? "Dark mode ativado com sucesso!" : "Light mode ativado com sucesso!");
        setActionCounter(0);
        setOpen(true);
        return;
      }

      changeRootTheme(!isDark ? "biud-dark" : "biud-light");
      setMessage(!isDark ? "Dark mode ativado com sucesso!" : "Light mode ativado com sucesso!");
      setActionCounter(0);
      setOpen(true);
    }
  }, [actionCounter]);

  return (
    <>
      <span className="version-info" style={{ color: "#A3A3A3" }} onClick={() => setActionCounter((old) => old + 1)}>
        Versão {versionNumber}
      </span>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
