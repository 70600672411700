import { HTMLAttributes } from "react";
import "./styles.scss";
import { clsx } from "clsx";

export interface IMenuRootProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function MenuUpperContainer({ className, ...rest }: IMenuRootProps) {
  return <nav className={clsx("menu-navigation-upper-container", className)} {...rest} />;
}

export default MenuUpperContainer;
