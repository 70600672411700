import { HTMLAttributes } from "react";
import "./styles.scss";
import { clsx } from "clsx";
import { isNetworkPage } from "@services/utils";

export interface IMenuRootProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function MenuRoot({ className, ...rest }: IMenuRootProps) {
  const isNtPage = isNetworkPage();

  return <nav className={clsx("menu-navigation", isNtPage && "network-menu", className)} {...rest} />;
}

export default MenuRoot;
