import { forwardRef, InputHTMLAttributes, LegacyRef, useEffect, useRef, useState, Dispatch } from "react";
import "./styles.css";

interface ISearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  setState?: Dispatch<any>;
}

const SearchInput = forwardRef(({ className = "", setState = () => "", ...rest }: ISearchInputProps, ref) => {
  const timeoutRef = useRef(null as number | null);
  const [inputLoading, setInputLoading] = useState("");

  const setStateDelayed = (newState: any, delay: any, callback: any) => {
    if (delay === 0 || delay === undefined) {
      setState(newState);
    } else {
      if (timeoutRef?.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = window.setTimeout(() => {
        if (callback) callback();
        setState(newState);
        timeoutRef.current = null;
      }, delay);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const clearStats = () => setInputLoading("");

  return (
    <input
      ref={ref as LegacyRef<HTMLInputElement> | undefined}
      type="text"
      placeholder="Faça sua busca"
      className={`search-input-grey ${className} ${inputLoading}`}
      onChange={(e) => setStateDelayed(e.target.value, 1000, clearStats)}
      onInput={() => setInputLoading("search-input-loading")}
      {...rest}
    />
  );
});

SearchInput.displayName = "SearchInput";
export default SearchInput;
