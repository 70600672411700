import { Helmet } from "react-helmet";

export default function ActiveCampaignScript() {
  const tokens: Record<string, string> = {
    prod: "1002679368",
    "sebrae-prod": "1002679368",
  };

  const env = import.meta.env.VITE_ENV as "prod" | "sebrae-prod" | "hmg" | "dev";
  const activeCampaignToken = tokens[env];

  if (!activeCampaignToken) {
    return <Helmet />;
  }

  return (
    <Helmet>
      <script type="text/javascript">
        {`(function (e, t, o, n, p, r, i) {
        e.visitorGlobalObjectAlias = n;
        e[e.visitorGlobalObjectAlias] =
          e[e.visitorGlobalObjectAlias] ||
          function () {
            (e[e.visitorGlobalObjectAlias].q = e[e.visitorGlobalObjectAlias].q || []).push(arguments);
          };
        e[e.visitorGlobalObjectAlias].l = new Date().getTime();
        r = t.createElement("script");
        r.src = o;
        r.async = true;
        i = t.getElementsByTagName("script")[0];
        i.parentNode.insertBefore(r, i);
      })(window, document, "https://diffuser-cdn.app-us1.com/diffuser/diffuser.js", "vgo");
      vgo("setAccount", "${activeCampaignToken}");
      vgo("setTrackByDefault", true);
      vgo("process");`}
      </script>
    </Helmet>
  );
}
