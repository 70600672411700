import { useLazyQuery } from "@apollo/client";
import schema from "./data";

export function useCallQuery(key: keyof typeof schema.becalls, options?: Parameters<typeof useLazyQuery>[1]) {
  if (!schema.becalls[key]) {
    throw new Error(`A chave "${key as string}" não existe no schema.becalls.`);
  }

  const query = schema.getQuery(key);
  const responseKey = schema.getResponseKey(key);

  const [executeQuery, { data, loading, refetch, error }] = useLazyQuery(query, options);
  return {
    executeQuery,
    data: responseKey ? data?.[responseKey] : undefined,
    loading,
    refetch,
    error,
  };
}
