import { ReactNode } from "react";
import "./styles.scss";
import ArrowUpRightSmall from "@newcomponentes/CustomIcons/Arrows/ArrowUpRightSmall";

export interface IMenuRedirectProps {
  href: string;
  name: string;
  icon: ReactNode;
  tooltip: string;
}

function MenuRedirect({ href, name, icon, tooltip }: IMenuRedirectProps) {
  return (
    <div className="redirect-unit-view-container">
      <a href={href} target="_blank" title={tooltip} className="see-network-link" rel="noreferrer">
        <div className={`item`}>
          {icon}
          {name}
          <ArrowUpRightSmall style={{ width: 16, height: 16, marginBottom: -2 }} viewBox="0 0 16 16" />
        </div>
      </a>
    </div>
  );
}

export default MenuRedirect;
