import { useState } from "react";
import "./styles.scss";
import { BiudButton } from "@newcomponentes";
import { imgClose, imgLogoutBlue } from "@imgs";
import { CircularProgress } from "@mui/material";
import { sleep } from "@utils";

interface ILogoutModalContentProps {
  title: string;
  text?: string;
  closeModal: (param?: any, param2?: any) => void;
  onButtonClickConfirm: (param?: any, param2?: any) => void;
}

export default function LogoutModalContent({
  title,
  text,
  closeModal,
  onButtonClickConfirm = () => "",
}: ILogoutModalContentProps) {
  const [loading, setLoading] = useState(false);

  window.onclick = (event) => {
    if (event.target === document.querySelector(".modal-flex")) {
      closeModal();
    }
  };
  return (
    <div className="logout-modal-container">
      <img alt={"Fechar"} className="modal-close" src={imgClose} onClick={() => closeModal()} />
      <div className="rounded rounded-background rounded-background-blue logout-title-image">
        <img alt={"Logout"} src={imgLogoutBlue} width="24px" onClick={() => closeModal()} />
      </div>
      <h2 className="modal-title">{title}</h2>
      {text && <p className="modal-text color-gray text-center">{text}</p>}
      <div className="button-space flex">
        <BiudButton
          buttonType="text-secondary"
          size="md"
          onClick={closeModal}
          style={{ marginRight: "8px", justifyContent: "center" }}
        >
          Cancelar
        </BiudButton>
        <BiudButton
          buttonType="primary"
          size="md"
          style={{ minWidth: "135.92px" }}
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            await sleep(500);
            onButtonClickConfirm();
          }}
        >
          {loading ? <CircularProgress size={20} style={{ color: "#FFFFFF" }} /> : "Tenho certeza"}
        </BiudButton>
      </div>
    </div>
  );
}
