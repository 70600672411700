import { startTransition, useEffect, useState, JSX, Dispatch, SetStateAction } from "react";
import "./styles.css";
import imgClose from "@imgs/close.svg";
import imgRocket from "@imgs/rocket.png";
import { useDispatch } from "react-redux";
import storage from "../../store/storage";
import { useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { removeAccent } from "@services/utils";
import { RiArrowRightLine } from "react-icons/ri";
import { getUrlImagesFB } from "@services/firebase";
import Step3 from "@newcomponentes/LoginContent/Step3";
import companyAction from "@store/Company/CompanyAction";
import CompanyAction from "@store/Company/CompanyAction";
import { setCurrentUser } from "@store/CurrentUser/CurrentUserAction";
import { BIUD_CONNECT_INSTALLED_QUERY, BUSINESS_USERS_QUERY } from "@query/gql/business";
import { IBusiness } from "@/graphql/types";
import { handleMiaUserkey } from "@/services/apis/mia/miaBiudApi";
import {
  biudConnectSet,
  isAdmin,
  selectCompany,
  selectNameCompany,
  selectImageCompany,
  setIsDataSharing,
  setCompany,
  getCompany,
  companySelected,
  getGroup,
  isSeller,
  getCurrentUserEmail,
  addUserType,
  isComercial,
} from "@services/auth";
import { BiudButton } from "@newcomponentes";

interface IChooseBusinessModalContentProps {
  onClose?: (param?: any, param2?: any) => void;
  groupsQuantity?: number;
  backBtnCallback?: (param?: any, param2?: any) => void;
  setModalContent?: Dispatch<SetStateAction<JSX.Element>>;
  setModalVisible?: Dispatch<SetStateAction<boolean>>;
  setModalSize?: Dispatch<SetStateAction<string>>;
  openChooseBusinessGroupModal?: (param?: any, param2?: any) => void;
}

function ChooseBusinessModalContent({
  onClose = () => "",
  groupsQuantity = 0,
  backBtnCallback = () => "",
  setModalContent = () => "",
  setModalVisible = () => "",
  setModalSize = () => "",
  openChooseBusinessGroupModal = () => "",
}: IChooseBusinessModalContentProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [selectedCompany, setSelectedCompany] = useState({} as any);
  const [businessGroup, setBusinessGroup] = useState({} as any);
  const [companies, setCompanies] = useState<IBusiness[]>([]);
  const [autoSelect, setAutoSelect] = useState(false);

  const [getBusinessUsers] = useLazyQuery(BUSINESS_USERS_QUERY);
  const [isBiudConnectInstalled] = useLazyQuery(BIUD_CONNECT_INSTALLED_QUERY);

  useEffect(() => {
    const actualBusinessGroup = getGroup();
    setBusinessGroup(actualBusinessGroup);
    const actualSelectedCompany = getCompany();
    if (!actualSelectedCompany) return;
    setSelectedCompany(actualSelectedCompany);
  }, []);

  async function handleViewBusinessButtonClicked() {
    if (!selectedCompany?.id) return;
    onClose();
    try {
      dispatch(CompanyAction.setLoading(true));
      dispatch(companyAction.setIsChooseGroupBusinessRequired(false));
      const resReq = await isBiudConnectInstalled({
        variables: { id: selectedCompany.id },
        fetchPolicy: "no-cache",
      });
      const res = resReq.data?.Business?.data;
      let firebaseImgUrl;
      if (selectedCompany.logo && import.meta.env.VITE_ENV === "prod") {
        try {
          const url = await getUrlImagesFB(selectedCompany.logo);
          firebaseImgUrl = url;
        } catch (err) {
          console.log(`Imagem não encontrada no firebase!`);
        }
      }
      biudConnectSet(res[0].biudConnect);
      selectCompany(selectedCompany.id);
      setCompany(selectedCompany);
      selectNameCompany(selectedCompany.name);
      selectImageCompany(firebaseImgUrl || imgRocket);
      setIsDataSharing(selectedCompany.isDataSharingEnabled);
      if (isAdmin()) {
        dispatch(setCurrentUser("ADMIN"));
      } else {
        const userEmail = getCurrentUserEmail();
        const resReq = await getBusinessUsers({ variables: { id: selectedCompany.id }, fetchPolicy: "no-cache" });
        const res = resReq.data?.Business?.data;
        const usersList = res?.[0]?.users;
        const currentUser = usersList?.find(({ user }: any) => user.email === userEmail);
        addUserType(currentUser?.type);
        dispatch(setCurrentUser(currentUser?.type));
      }

      if (import.meta.env.VITE_MIA_API_URL) {
        await handleMiaUserkey();
      }
    } catch (error) {
      console.error(error);
    } finally {
      startTransition(() => {
        const url_atual = window.location.href;
        const currentPage = url_atual.split("/").at(-1);
        storage.removeItem("checkPayment");

        if (isSeller()) {
          if (currentPage !== "customers") {
            history.push("/internal/customers");
          }
        }
        history.go(0);
      });
    }
  }

  function createItem(company: any, index: any) {
    return (
      <div
        key={`choose-company-item-${company.id}`}
        id={`container-choosebiz-op-${index}`}
        className="choose-company-empty-content"
        onClick={() => setSelectedCompany(company)}
      >
        <input
          type="radio"
          name="company"
          id={`field-choosebiz-op-${index}`}
          value={company.id}
          defaultChecked={company.id === companySelected()}
        />
        <label id={`field-choosebiz-op-label-${index}`} htmlFor={`field-choosebiz-op-${index}`}>
          {company.name}
        </label>
      </div>
    );
  }

  const searchCompanies = () => {
    try {
      const group = getGroup();
      if (group) setCompanies(group.businesses);
    } catch (err) {
      console.log(err);
    }
  };

  const closeModal = () => {
    setModalContent(<></>);
    setModalVisible(false);
  };

  const openCreateBusinessModal = (groupId: number) => {
    setModalContent(
      <Step3
        setStep={() => ""}
        animation="anime-swing-in"
        loading={false}
        setLoading={() => ""}
        setNewBusinessInfo={() => ""}
        endOnboarding={closeModal}
        primaryBtnTitle={"Criar empresa"}
        secondaryBtnTitle={"Cancelar"}
        groupId={groupId}
        openChooseBusinessGroupModal={openChooseBusinessGroupModal}
      />
    );
    setModalSize("auto");
  };

  const handleSearchCompanies = () => {
    if (!companies || companies.length === 0) {
      searchCompanies();
    }
  };

  useEffect(handleSearchCompanies, [companies, searchCompanies]);
  useEffect(() => {
    if (!getCompany() && companies?.length === 1 && !Object.keys(selectedCompany).length) {
      setSelectedCompany(companies[0]);
      setAutoSelect(true);
    }
  }, [selectedCompany, companies, getCompany()]);
  useEffect(() => {
    if (!selectedCompany || !autoSelect) {
      return;
    }
    handleViewBusinessButtonClicked();
  }, [selectedCompany, autoSelect]);

  return (
    <>
      {!!getCompany() && <img alt="Fechar" className="modal-close float-right" src={imgClose} onClick={onClose} />}
      <div className="choose-company-content">
        <div className="choose-company-header">
          <div
            className={`choose-company-logo ${businessGroup?.image && "rounded"}`}
            style={{ backgroundImage: `url('${businessGroup?.image ?? imgRocket}')` }}
          />
          <h3>{businessGroup?.name}</h3>
        </div>
        <hr />
        <div>Selecione uma empresa</div>
        {companies && (
          <input
            autoFocus
            id={`field-choosebiz-search`}
            className={"input-search"}
            onChange={(e) => setSearch(e.target.value)}
          />
        )}
        <div className="companies-list">
          {companies &&
            companies
              .map((comp: any) => {
                if (isComercial()) {
                  if (comp.name.includes("Drogafuji")) {
                    const drogariaBiud = { ...comp };
                    drogariaBiud.logo = null;
                    drogariaBiud.name = "Drogaria BIUD";
                    return drogariaBiud;
                  }

                  if (comp.name.includes("Ricco")) {
                    const hambBiud = { ...comp };
                    hambBiud.logo = null;
                    hambBiud.name = "Hamburgueria BIUD";
                    return hambBiud;
                  }
                }

                return comp;
              })
              .filter((company: any) => {
                if (!search || search.length === 0) return true;
                return removeAccent(company.name).toUpperCase().indexOf(removeAccent(search.toUpperCase())) >= 0;
              })
              .map((company: any, index: any) => createItem(company, index))}
        </div>
        <BiudButton
          id={`btn-choosebiz-showbiz`}
          className="choose-company-button-select clickable-with-effect"
          buttonType="primary"
          size="md"
          disabled={!selectedCompany?.id}
          onClick={handleViewBusinessButtonClicked}
        >
          Visualizar empresa
          <RiArrowRightLine />
        </BiudButton>
        <hr />
        <BiudButton
          buttonType="primary-outlined"
          size="md"
          id={`btn-choosebiz-createnetwork`}
          className="business-group-card-button clickable-with-effect"
          onClick={() => openCreateBusinessModal(businessGroup.id)}
        >
          Criar nova empresa nesta rede
        </BiudButton>
        {groupsQuantity > 1 ||
          (!groupsQuantity && (
            <BiudButton
              buttonType="primary-outlined"
              size="md"
              id={`btn-choosebiz-choosenetwork`}
              className="business-group-card-button clickable-with-effect"
              onClick={backBtnCallback}
            >
              Escolher outra rede
            </BiudButton>
          ))}
      </div>
    </>
  );
}

export default ChooseBusinessModalContent;
