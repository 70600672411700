const schema = {
  pending: {
    title: "Não feche essa janela ou recarregue a página.",
    text: "Estamos trabalhando na construção do seu filtro.",
    isLoading: true,
  },
  completed: {
    title: "Seu filtro está pronto!",
    resultBtn: "Ver Resultado",
    closeBtn: "Fechar esse aviso",
  },
  error: {
    title: "Ocorreu um erro! Verifique e tente novamente",
    closeBtn: "Fechar esse aviso",
  },
  timeout: {
    title: "Sua requisição excedeu o tempo limite (5 min). Tente novamente mais tarde.",
    closeBtn: "Fechar esse aviso",
  },
};

export default schema;
