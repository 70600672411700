import "./styles.scss";
import ButtonClose from "../../Buttons/Close";
import BiudButton from "../../Buttons/BiudButton";
import { ExclamationTriangle } from "../../CustomIcons";

interface IFailModalContentProps {
  title?: string;
  text?: string;
  mainBtnOnClick?: () => void;
  mainBtnText?: string;
  secondarybtnOnClick?: () => void;
  secondaryBtnText?: string;
  closeModal?: () => void;
}

function FailModalContent({
  title = "",
  text = "",
  mainBtnOnClick = () => "",
  mainBtnText = "Botão",
  secondarybtnOnClick = () => "",
  secondaryBtnText,
  closeModal = () => "",
}: IFailModalContentProps) {
  const handleText = () => {
    const regex = /\[([^)]+)]/;
    if (!text) return "";

    if (text.includes("[")) {
      const textArray = text.split(regex);
      const error = textArray[0];
      const errorList = textArray[1].split(",");
      return (
        <div className="error-text-container">
          <span>{error}</span>
          <ul className="error-text-list-container">
            {errorList.map((error, index) => (
              <li key={`${error}-${index}`}>{error}</li>
            ))}
          </ul>
        </div>
      );
    }

    return text;
  };

  return (
    <section className="fail-modal-container anime-slit-in-hr">
      <header className="fail-header-container">
        <div className="return-btn"></div>
        <div className="icon-container">
          <ExclamationTriangle />
        </div>
        <div className="close-btn-container">
          <ButtonClose onClick={() => closeModal()} />
        </div>
      </header>
      <main className="fail-main-container">
        <p className="title-msg">{title}</p>
        {text && (
          <p id="fit-modal-text" className={`sf-main-text`}>
            {handleText()}
          </p>
        )}
      </main>
      <footer>
        {secondaryBtnText && (
          <BiudButton buttonType={`text-secondary`} size={`lg`} onClick={() => secondarybtnOnClick()} bold={false}>
            {secondaryBtnText}
          </BiudButton>
        )}
        {mainBtnText && (
          <BiudButton
            buttonType={`tertiary`}
            size={`lg`}
            onClick={() => mainBtnOnClick()}
            bold={false}
            style={{ width: "300px" }}
          >
            {mainBtnText}
          </BiudButton>
        )}
      </footer>
    </section>
  );
}

export default FailModalContent;
