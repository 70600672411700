import { createContext, useContext } from "react";
import { IGlobalData, IGlobalDataProviderProps, AuthContextType } from "./types";
import { IPlanSubscription } from "@services/apis/biudPlans/types";
import useCheckAccess from "@context/GlobalContext/hooks/useCheckAccess";
import usePlanSubscription from "@context/GlobalContext/hooks/usePlanSubscription";
import useKeycloak from "@context/GlobalContext/hooks/useKeycloak";
import { useCallQuery } from "@context/GlobalContext/hooks/useCallQuery";
import usePreserveData from "@context/GlobalContext/hooks/usePreserveData";
import { LinearProgress } from "@mui/material";

const GlobalContext = createContext<(IGlobalData & AuthContextType) | undefined>(undefined);

function GlobalDataProvider({ children }: IGlobalDataProviderProps) {
  const { dataPlanSubscribed, loadingDataPlanSubscribed, fetchPlanSubscription } = usePlanSubscription();
  const { accessData, loadingCheckAccess, checkAccess } = useCheckAccess();
  const { loadingAuth, ...restKeycloack } = useKeycloak();
  const restCustomerData = usePreserveData();

  const contextValues: IGlobalData = {
    currentPlan: {
      data: dataPlanSubscribed,
      isLoading: loadingDataPlanSubscribed,
      fetch: fetchPlanSubscription,
    },
    checkAccess: {
      data: accessData,
      isLoading: loadingCheckAccess,
      fetch: checkAccess,
    },
    useCallQuery,
    ...(restKeycloack || {}),
    ...(restCustomerData || {}),
  };

  if (loadingAuth)
    return (
      <LinearProgress sx={{ backgroundColor: "white", "& .MuiLinearProgress-bar": { backgroundColor: "#C4C4C4" } }} />
    );

  return <GlobalContext.Provider value={contextValues}>{children}</GlobalContext.Provider>;
}

export const useGlobalData = () => {
  const context = useContext(GlobalContext);
  if (!context) throw new Error("useGlobalData deve ser usado dentro de um GlobalDataProvider");
  return context;
};

export type { IGlobalData, IGlobalDataProviderProps, IPlanSubscription };
export default GlobalDataProvider;
