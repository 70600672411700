import { useEffect, useState, useRef, useCallback } from "react";
import "./styles.scss";
import Store from "@generics/Store";
import MemberKit from "@generics/MemberKit";
import { useHistory } from "react-router-dom";
import { currentLocation, isNetworkPage } from "@services/utils";
import { TypeSchema } from "@generics/GenSvgIcon";
import MenuRoot from "@components/Menu/MenuRoot";
import { renderMenuBrand } from "@/themes/utils";
import Version from "@components/Version/Version";
import BuildingSmall from "@generics/BuildingSmall";
import BiudMenuItem from "@components/BiudMenuItem";
import BiudNavItem from "@newcomponentes/BiudNavItem";
import BiudPartner from "@components/Menu/BiudPartner";
import MenuRedirect from "@components/Menu/MenuRedirect";
import BiudMenuItemGroup from "@components/BiudMenuItemGroup";
import { INavMenuV1Props } from "@components/Menu/NavMenuV1/types";
import { menuScheme } from "@components/Menu/NavMenuV1/menuScheme";
import MenuItemContainer from "@components/Menu/MenuItemContainer";
import MenuUpperContainer from "@components/Menu/MenuUpperContainer";
import { handleNameLength } from "@components/Menu/NavMenuV1/helper";
import { getAuthenticatedUrl } from "@services/apis/memberkit/requests";
import { getMemberKit, getMenuSettings, isSebrae, setMemberKit, setMenuSettings } from "@services/auth";

function NavMenuV1({
  openChooseBusinessModal,
  openChooseBusinessGroupModal,
  business,
  businessGroup,
}: INavMenuV1Props) {
  const currentPage = currentLocation() || "";
  const fullPath = window.location.pathname || "";

  const isNtPage = isNetworkPage();
  const history = useHistory();
  const settings = getMenuSettings();

  const url = window.location.href;
  const bizGrMenu = url.includes("network");

  const [openedMenuGroup, setOpenedMenuGroup] = useState<string>(settings?.menuGroup || "");
  const [menuSelected, setMenuSelected] = useState<string>(currentPage || settings?.menu || "home");
  const [windowWidth, setWindowWidth] = useState(0);
  const [memberKitUrl, setMemberKitUrl] = useState(getMemberKit() || "");
  const [loading, setLoading] = useState(!memberKitUrl);
  const menuRef = useRef<HTMLDivElement>(null);

  async function fetchMemberKitUrl() {
    try {
      if (memberKitUrl) return;
      setLoading(true);
      const memberUrl = await getAuthenticatedUrl();
      setMemberKit(memberUrl);
      setMemberKitUrl(memberUrl);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleOpenMenu = useCallback(
    (name: string) => {
      setOpenedMenuGroup((old) => (old === name ? "empty" : name));
      setMenuSettings({ menu: menuSelected, menuGroup: name });
    },
    [menuSelected]
  );

  const handleMenuItemClick = useCallback(
    (name: string, isSubMenu = false, search?: string) => {
      setMenuSelected(name);
      setMenuSettings({ menu: name, menuGroup: isSubMenu ? openedMenuGroup : "" });
      if (!isSubMenu) setOpenedMenuGroup("");
      history.push({ pathname: name, search });

      if (windowWidth < 1025) {
        const menu = document.querySelector(".menu-navigation") as HTMLDivElement;
        if (!menu) return;
        menu.style.display = "none";
      }
    },
    [history, windowWidth]
  );

  useEffect(() => {
    if (currentPage) {
      const menuItems = menuScheme[isNtPage ? "networkMenu" : "businessMenu"];
      const matchedItem = menuItems.find(
        (item) => item?.submenus?.length && item.submenus.some((sub) => sub.url.includes(currentPage))
      );
      if (matchedItem && openedMenuGroup !== matchedItem?.url) {
        setOpenedMenuGroup(matchedItem?.url);
      }
    }
  }, [currentPage, isNtPage, openedMenuGroup]);

  useEffect(() => {
    const reportWindowSize = () => {
      setWindowWidth(window.innerWidth);
      if (menuRef.current) {
        menuRef.current.style.display = window.innerWidth > 1024 ? "flex" : "none";
      }
    };
    reportWindowSize();
    fetchMemberKitUrl();
    window.addEventListener("resize", reportWindowSize);
    return () => window.removeEventListener("resize", reportWindowSize);
  }, []);

  return (
    <MenuRoot>
      <MenuUpperContainer>
        <BiudNavItem
          title={isNtPage ? handleNameLength(businessGroup.name) : handleNameLength(business.name)}
          icon="Exchange"
          onClick={isNtPage ? openChooseBusinessGroupModal : openChooseBusinessModal}
        />
        <MenuItemContainer ref={menuRef}>
          {menuScheme[isNtPage ? "networkMenu" : "businessMenu"]
            .filter((item) => item?.isEnabled)
            .map((item) => {
              if (item?.submenus?.length) {
                return (
                  <BiudMenuItemGroup
                    key={`${item.name}-${item.url}`}
                    title={item.name}
                    icon={item.icon as TypeSchema | ""}
                    onClick={() => handleOpenMenu(item.url)}
                    isOpen={openedMenuGroup === item.url}
                    isBlackMode={isNtPage}
                  >
                    {item?.submenus
                      ?.filter((subItem) => subItem.isEnabled)
                      ?.map((subMenuItem) => (
                        <BiudMenuItem
                          key={`subitem-${subMenuItem.name}-${subMenuItem.url}`}
                          isSecondary={true}
                          title={subMenuItem.name}
                          isSelected={subMenuItem.url.includes(currentPage)}
                          onClick={() => handleMenuItemClick(subMenuItem.url, true, subMenuItem.search)}
                          isBlackMode={isNtPage}
                        />
                      ))}
                  </BiudMenuItemGroup>
                );
              }
              return (
                <BiudMenuItem
                  key={`${item.name}-${item.url}`}
                  title={item.name}
                  icon={item.icon as TypeSchema | ""}
                  isSelected={item.url.includes(currentPage) || fullPath.includes(item.url)}
                  onClick={() => handleMenuItemClick(item.url)}
                  isBlackMode={isNtPage}
                  isDisabledSoft={item?.isAdminOnly || false}
                />
              );
            })}
        </MenuItemContainer>
        {!isNtPage ? (
          <>
            {businessGroup && businessGroup?.businesses?.length > 1 && (
              <MenuRedirect
                href="/internal/network/home"
                tooltip="Abrir visualização de rede"
                name="Rede"
                icon={<BuildingSmall style={{ fontSize: "14px" }} />}
              />
            )}
            {!loading && !!memberKitUrl && (
              <MenuRedirect
                href={memberKitUrl}
                tooltip="Abrir página do MemberKit"
                name="Academy"
                icon={<MemberKit style={{ fontSize: "16px" }} />}
              />
            )}
          </>
        ) : (
          <MenuRedirect
            href="/internal/home"
            tooltip="Abrir visualização de unidadee"
            name="Unidades"
            icon={<Store style={{ fontSize: "14px" }} />}
          />
        )}
      </MenuUpperContainer>
      <div className="version-container">
        {!isSebrae() ? renderMenuBrand(bizGrMenu) : <BiudPartner />}
        <Version />
      </div>
    </MenuRoot>
  );
}

export default NavMenuV1;
