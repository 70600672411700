import React from "react";
import "./styles.scss";
import BiudBrands from "@components/BiudBrands";

function BiudPartner() {
  return (
    <section className="menu-biud-partner-container">
      <span className="menu-partner-brand-container">
        <span>Uma tecnologia </span>
        <BiudBrands brand="biud-full" width="68px" height="24px" className="menu-partner-brand" />
      </span>
      <p className="menu-partner-text">
        A BIUD gera análises com base nos dados históricos do seu negócio. Os resultados são estimativas e podem variar
        conforme as circunstâncias do mercado.
      </p>
    </section>
  );
}

export default BiudPartner;
