import { ACTIVATION } from "./ActivationReducer";

export const setName = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.NAME,
    payload,
  });
};

export const setListFile = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.LISTFILE,
    payload,
  });
};

export const setDescription = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.DESCRIPTION,
    payload,
  });
};

export const setNoiseControl = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.NOISE_CONTROL,
    payload,
  });
};

export const setNoiseControlDays = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.NOISE_CONTROL_DAYS,
    payload,
  });
};

export const setDynamicProducts = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.DYNAMIC_PRODUCTS,
    payload,
  });
};

export const setStartAt = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.START_AT,
    payload,
  });
};

export const setEndAt = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.END_AT,
    payload,
  });
};

export const setBusinessId = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.BUSINESS_ID,
    payload,
  });
};

export const setProductIds = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.PRODUCT_IDS,
    payload,
  });
};

export const setVehicles = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.VEHICLES,
    payload,
  });
};

export const setDispatchMoment = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.DISPATCH_MOMENT,
    payload,
  });
};

export const setDispatchRepeatFrequency = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.DISPATCH_REPEAT_FREQUECY,
    payload,
  });
};

export const setDispatchRepeatedlyAt = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.DISPATCH_REPEAT_AT,
    payload,
  });
};
export const setActivationFrequency = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.DISPATCH_ACTIVATION_FREQUENCY,
    payload,
  });
};

export const setDynamicListId = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.DYNAMIC_LIST_ID,
    payload,
  });
};

export const setScheduledTo = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.SCHEDULED_TO,
    payload,
  });
};

export const setTemplateQueryFilterId = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.TEMPLATE_QUERY_FILTER_ID,
    payload,
  });
};

export const setWhatsappTemplates = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.WHATSAPP_TEMPLATES,
    payload,
  });
};

export const setCustomerFilter = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.CUSTOMER_FILTER,
    payload,
  });
};

export const setPublicInfo = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.PUBLIC_INFO,
    payload,
  });
};

export const setProductsLink = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.PRODUCTS_LINK,
    payload,
  });
};

export const setSelectedTemplate = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.SELECTED_TEMPLATE,
    payload,
  });
};

export const setSelectedNumber = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.SELECTED_NUMBER,
    payload,
  });
};

export const setProductsSelected = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.PRODUCTS_SELECTED,
    payload,
  });
};

export const setEndAtText = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.END_AT_TEXT,
    payload,
  });
};

export const setAnalysisStartAt = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.ANALYSIS_START_AT,
    payload,
  });
};

export const setAnalysisEndAt = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.ANALYSIS_END_AT,
    payload,
  });
};

export const setMiaActivation = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.MIA_ACTIVATION,
    payload,
  });
};

export const setRelatedBusinessesId = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.RELATED_BUSINESSES_ID,
    payload,
  });
};

export const setBusinessSelected = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.BUSINESS_SELECTED,
    payload,
  });
};

export const setGroupId = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.GROUP_ID,
    payload,
  });
};

export const setToEnrich = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.TO_ENRICH,
    payload,
  });
};

export const setToAnalyze = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.TO_ANALYZE,
    payload,
  });
};

const setCustomersAmount = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.CUSTOMERS_AMOUNT,
    payload,
  });
};

const setMaxPeopleDispatch = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.MAX_PEOPLE_DISPATCH,
    payload,
  });
};

const setLeadLoversMachineCode = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.LEADLOVERS_MACHINE_CODE,
    payload,
  });
};

const setInitialState = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.INITIAL_STATE,
    payload,
  });
};

const setIsHotLead = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.IS_HOTLEAD,
    payload,
  });
};

const setHydratedBy = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.HIDRATED_BY,
    payload,
  });
};

const setHotleadFilter = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.HOTLEAD_FILTER,
    payload,
  });
};
const setSenderBy = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.SENDER_BY,
    payload,
  });
};
const setMessageContent = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.MESSAGE_CONTENT,
    payload,
  });
};

export const clear = () => (dispatch: any) => {
  dispatch({
    type: ACTIVATION.CLEAR,
  });
};

const functions = {
  setName,
  setListFile,
  setDescription,
  setNoiseControl,
  setNoiseControlDays,
  setDynamicProducts,
  setStartAt,
  setEndAt,
  setBusinessId,
  setProductIds,
  setVehicles,
  setDispatchMoment,
  setDispatchRepeatFrequency,
  setDispatchRepeatedlyAt,
  setActivationFrequency,
  setDynamicListId,
  setScheduledTo,
  setTemplateQueryFilterId,
  setWhatsappTemplates,
  setCustomerFilter,
  setPublicInfo,
  setProductsLink,
  setSelectedTemplate,
  setSelectedNumber,
  setProductsSelected,
  setEndAtText,
  setAnalysisStartAt,
  setAnalysisEndAt,
  setMiaActivation,
  setRelatedBusinessesId,
  setBusinessSelected,
  setGroupId,
  setToEnrich,
  setToAnalyze,
  setCustomersAmount,
  setMaxPeopleDispatch,
  setLeadLoversMachineCode,
  setInitialState,
  setIsHotLead,
  setHydratedBy,
  setHotleadFilter,
  setSenderBy,
  setMessageContent,
  clear,
};

export default functions;
