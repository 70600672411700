import { clsx } from "clsx";
import { isGsGroup } from "@services/utils";
import { isSebrae, isSebraeEnv } from "@services/auth";
import { brandsSchema } from "@/themes/data";
import BiudBrands, { TBrand } from "@components/BiudBrands";
import { TRootThemeName } from "@context/GlobalContext/types";

export const getDefaultTheme = () => {
  return (localStorage.getItem("rootTheme") as TRootThemeName) || (isSebrae() ? "sebrae" : "biud-light");
};

const persistRootTheme = (theme: TRootThemeName) => {
  localStorage.setItem("rootTheme", theme);
};

export const isValidTheme = (theme: TRootThemeName) => {
  if (!theme) return false;
  return ["biud-light", "biud-dark", "sebrae", "gen"].includes(theme);
};

export const changeRootTheme = (theme: TRootThemeName) => {
  let selectedTheme = theme || "biud-light";

  if (!isValidTheme(selectedTheme)) {
    selectedTheme = "biud-light";
  }

  if (isSebraeEnv()) {
    selectedTheme = "sebrae";
  }

  document.documentElement.className = `${selectedTheme}-theme`;
  persistRootTheme(selectedTheme);
};

export const renderHeaderBrand = () => {
  let brand = brandsSchema.biudHeader;
  if (isGsGroup()) {
    brand = brandsSchema.genHeader;
  }

  if (isSebrae()) {
    brand = brandsSchema.sebraeHeader;
  }
  return <BiudBrands brand={brand.name} width={brand.width} height={brand.height} style={{ margin: "0 32px" }} />;
};

export const renderMenuBrand = (isNetworkPage = false) => {
  let brand: TBrand = brandsSchema.biudMenu.name;
  if (isGsGroup()) {
    brand = isNetworkPage ? brandsSchema.genWhiteMenu.name : brandsSchema.genMenu.name;
  }
  if (isSebrae()) {
    brand = brandsSchema.sebraeMenu.name;
  }
  return <BiudBrands brand={brand} />;
};

export const renderLoadingImg = () => {
  let brand: TBrand = brandsSchema.biudLoading.name;
  const isGs = isGsGroup();
  if (isGs) {
    brand = brandsSchema.genLoading.name;
  }
  if (isSebrae()) {
    brand = brandsSchema.sebraeLoading.name;
  }
  return <BiudBrands brand={brand} className={clsx("biud-loading", isGs && "gen-logo")} />;
};

export const renderLoginBrand = (isGs?: boolean) => {
  let brand = brandsSchema.biudLogin;
  if (isGsGroup() || isGs) {
    brand = brandsSchema.genLogin;
  }
  if (isSebrae()) {
    brand = brandsSchema.sebraeLogin;
  }

  return <BiudBrands brand={brand.name} style={{ width: brand?.width, height: brand?.height }} />;
};
