import { useEffect, useState } from "react";
import "./styles.css";
import Modal from "../Modal";
import { Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import Helper from "../NewComponentes/Helper";
import IconButton from "@mui/material/IconButton";
import { getGroup, logout } from "@services/auth";
import { renderHeaderBrand } from "@/themes/utils";
import { useGlobalData } from "@context/GlobalContext";
import LogoutModalContent from "../LogoutModalContent";
import { getCurrentEnvironment } from "@services/utils";
import Exit from "@newcomponentes/CustomIcons/Generics/Exit";
import { clear, logout as logoutRedux } from "../../store/store";
import InfoCircle from "@newcomponentes/CustomIcons/Generics/InfoCircle";
import { sleep } from "@utils";

interface IHeaderProps {
  isMenuEnabled?: boolean;
  className?: string;
}

export default function Header({ isMenuEnabled = true, className = "" }: IHeaderProps) {
  const dispatch = useDispatch();
  const { logout: keycloaklogout } = useGlobalData();

  const [visible, setVisible] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);
  const [windowWidth, setWindowWidth] = useState(0);

  const group = getGroup();

  const handleLogout = async () => {
    dispatch(logoutRedux());
    dispatch(clear());
    await logout(keycloaklogout);
  };

  const handleCloseModal = () => {
    setVisible(false);
    setModalContent(<></>);
  };

  function logoutContent() {
    setModalContent(
      <LogoutModalContent
        title="Tem certeza que deseja sair do sistema?"
        closeModal={() => handleCloseModal()}
        onButtonClickConfirm={handleLogout}
      />
    );
    setVisible(true);
  }

  function showTheMenu() {
    const menu: any = document.querySelector(".menu-navigation") as HTMLDivElement;
    menu.style.display = "flex";
  }

  const handleEnvironmentTooltip = () => {
    const envDict: { [key: string]: string } = {
      "sebrae-prod": "",
      prod: "",
      hmg: "O ambiente de homologação é a validação final antes de ir para produção.",
      dev: "O ambiente de desenvolvimento pode conter bugs e funcionalidades incompletas.",
    };
    return envDict[import.meta.env.VITE_ENV];
  };

  useEffect(() => {
    const reportWindowSize = () => {
      setWindowWidth(window.innerWidth);
    };
    reportWindowSize();
    window.addEventListener("resize", reportWindowSize);
    return () => window.removeEventListener("resize", reportWindowSize);
  }, []);

  return (
    <header className={className}>
      <nav className="Box">
        <div className="navLeft">
          {isMenuEnabled && (
            <div className="menu-responsive">
              <i
                className="fa fa-bars btn-menu-responsive"
                onClick={() => {
                  showTheMenu();
                }}
              />
            </div>
          )}
          <div className="flex">
            {group && renderHeaderBrand()}
            {!import.meta.env.VITE_ENV?.includes("prod") && (
              <div className="dev-env-text-msg">
                <span>{`Ambiente de ${getCurrentEnvironment()}`}</span>
                {windowWidth > 375 && (
                  <Tooltip title={handleEnvironmentTooltip()} style={{ cursor: "help" }}>
                    <span className="tooltip-text-msg">
                      <InfoCircle style={{ width: 18, height: 18 }} viewBox="0 0 15 15" />
                    </span>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="navRigth">
          <Helper />
          <IconButton id="btn-header-logout" className="x-small-img logout-image" title="Sair" onClick={logoutContent}>
            <Exit style={{ fontSize: "24px" }} />
          </IconButton>
        </div>
      </nav>
      <Modal visible={visible} content={modalContent} size="auto" />
    </header>
  );
}
