import { Helmet } from "react-helmet";

export default function GoogleTagManagerScript() {
  const tokens: Record<string, string> = {
    prod: "G-84H7XG5PV1",
    "sebrae-prod": "G-84H7XG5PV1",
  };

  const env = import.meta.env.VITE_ENV as "prod" | "sebrae-prod" | "hmg" | "dev";
  const googleTagToken = tokens[env];

  if (!googleTagToken) {
    return <Helmet />;
  }

  return (
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleTagToken}`}></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "${googleTagToken}");`}
      </script>
    </Helmet>
  );
}
