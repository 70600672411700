import { string, number, oneOfType } from "prop-types";
import "./styles.css";

function TitleNumber({ number }: any) {
  return <span className="title-number-container">{number}</span>;
}

TitleNumber.propTypes = {
  number: oneOfType([string, number]).isRequired,
};

export default TitleNumber;
