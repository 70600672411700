import { Helmet } from "react-helmet";

export default function ClarityScript() {
  const tokens: Record<string, string> = {
    prod: "mtjes57rbp",
    "sebrae-prod": "qudhfjqu7d",
  };

  const env = import.meta.env.VITE_ENV as "prod" | "sebrae-prod" | "hmg" | "dev";
  const clarityToken = tokens[env];

  if (!clarityToken) {
    return <Helmet />;
  }

  return (
    <Helmet>
      <script type="text/javascript">
        {`(function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${clarityToken}");`}
      </script>
    </Helmet>
  );
}
