import { ButtonHTMLAttributes } from "react";
import "./styles.scss";
import { TBiudButtonType } from "./types";

interface IBiudButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: TBiudButtonType;
  size?: "sm" | "md" | "lg";
  bold?: boolean;
  isCollapse?: boolean;
}

function BiudButton({
  id = "btn-biud-button",
  className = "",
  onClick = () => "",
  children,
  buttonType = "primary",
  size = "sm",
  bold = false,
  isCollapse = false,
  style = {},
  ...rest
}: IBiudButtonProps) {
  return (
    <button
      id={id}
      type="button"
      onClick={onClick}
      style={style}
      {...rest}
      className={`btn-default-setup btn-${buttonType} btn-size-${size} ${!bold ? "btn-bold-false" : ""} ${
        isCollapse ? "collapse-btn" : ""
      } ${className}`}
    >
      {children}
    </button>
  );
}

export type { IBiudButtonProps, TBiudButtonType };
export default BiudButton;
