import { useState, useCallback, useRef } from "react";
import { useCallQuery } from "@context/GlobalContext/hooks/useCallQuery";
import { IUseQueries } from "@context/GlobalContext/types";
import { CustomParams } from "../types";
import {
  FetchPolicy,
  QueryResult,
  OperationVariables,
  LazyQueryExecFunction,
  LazyQueryHookExecOptions,
} from "@apollo/client";

// Tipagem estendida para as opções, adicionando o campo "meta"
export interface ExtendedLazyQueryHookExecOptions<TData, TVariables extends OperationVariables>
  extends LazyQueryHookExecOptions<TData, TVariables> {
  category?: string;
  filterKey?: string;
  idAcess?: string;
}

// Tipo para a função lazy estendida
export type ExtendedLazyQueryExecFunction<TData, TVariables extends OperationVariables> = (
  options?: ExtendedLazyQueryHookExecOptions<TData, TVariables>
) => Promise<QueryResult<TData, TVariables>>;

type Options<TVariables extends OperationVariables> = {
  variables: TVariables;
  fetchPolicy?: FetchPolicy;
  category?: string;
  filterKey?: string;
  idAcess?: string;
  disregardedIds?: number[];
};

type RequestIndicator = {
  status: "pending" | "completed" | "error" | "timeout";
  visible: boolean;
  message: string;
  isBackgroundCall?: boolean;
};

function usePreserveData(): IUseQueries {
  // Obtém as funções de execução de query
  const getRegistrationPct = useCallQuery("withRegistration").executeQuery;
  const getCustomerById = useCallQuery("byId").executeQuery;
  const getDashTopProducts = useCallQuery("dashTopProducts").executeQuery;
  const getDashClusters = useCallQuery("dashCluster").executeQuery;
  const getDashProfile = useCallQuery("dashProfile").executeQuery;
  const getCustomersComplementTable = useCallQuery("complements").executeQuery;
  const getCustomersTable = useCallQuery("customers").executeQuery;
  const [results, setResults] = useState<Record<string, { data?: any; error?: any; loading?: boolean }>>({});
  const [requestIndicators, setRequestIndicators] = useState<Record<string, RequestIndicator>>({});
  const lastRequestIdsRef = useRef<Record<string, number>>({});
  const requestCounterRef = useRef(0);

  const allActions = {
    getRegistrationPct,
    getCustomerById,
    getDashTopProducts,
    getDashClusters,
    getDashProfile,
    getCustomersComplementTable,
    getCustomersTable,
  };

  function scheduleTimeouts(key: string, currentRequestId: number) {
    // 1° Timeout: marcar background após 1 minuto
    const backgroundTimerId = window.setTimeout(() => {
      // Atualiza somente se esta for a última requisição para essa key
      if (lastRequestIdsRef.current[key] === currentRequestId) {
        setRequestIndicators((prev) => {
          const indicator = prev[key];
          if (indicator && indicator.status === "pending") {
            return {
              ...prev,
              [key]: {
                ...indicator,
                isBackgroundCall: true,
                visible: true,
              },
            };
          }
          return prev;
        });
      }
    }, 60_000);
    // 2° Timeout: marcar timeout após 5 minutos (300s)
    const timeoutTimerId = window.setTimeout(() => {
      if (lastRequestIdsRef.current[key] === currentRequestId) {
        setRequestIndicators((prev) => {
          const indicator = prev[key];
          if (indicator && indicator.status === "pending") {
            return {
              ...prev,
              [key]: {
                ...indicator,
                status: "timeout",
                message: `A requisição excedeu 5 minutos`,
                visible: true,
                isBackgroundCall: false,
              },
            };
          }
          return prev;
        });
      }
    }, 300_000);
    return { backgroundTimerId, timeoutTimerId };
  }

  const wrappedFetch = useCallback(
    async <TVariables extends OperationVariables>(
      key: string,
      options: Options<TVariables>
    ): Promise<QueryResult<TVariables> | any | null> => {
      const { category, filterKey, idAcess, disregardedIds } = options;
      // Marcar o início da requisição imediatamente
      setResults((prev) => ({
        ...prev,
        [key]: { ...prev[key], loading: true, error: null },
      }));
      setRequestIndicators((prev) => ({
        ...prev,
        [key]: {
          status: "pending",
          visible: false,
          message: "Requisição em andamento...",
          isBackgroundCall: false,
        },
      }));
      // Não gera id imediatamente; agenda um timer de 50s para disparar os timeouts
      const controlTimer = window.setTimeout(() => {
        const currentRequestId = ++requestCounterRef.current;
        lastRequestIdsRef.current[key] = currentRequestId;
        // Aqui já agendamos os timeouts de 60s e 300s a partir desse momento
        scheduleTimeouts(key, currentRequestId);
      }, 50000);
      const finalFetchPolicy = options.fetchPolicy === "network-only" ? "network-only" : options.fetchPolicy;
      const isCustomerTable = key === "getCustomersTable";
      try {
        const executeQuery = allActions[key as keyof typeof allActions];
        if (!executeQuery) throw new Error(`Ação '${key}' não encontrada.`);
        const res = await executeQuery({
          variables: options.variables || {},
          fetchPolicy: finalFetchPolicy,
        });
        // Se a requisição terminar antes de 50s, cancela o timer de controle
        clearTimeout(controlTimer);
        // Atualiza o requestIndicator para "completed"
        setRequestIndicators((prev) => {
          const indicator = prev[key];
          return {
            ...prev,
            [key]: {
              ...indicator,
              status: "completed",
              message: "Resultado disponível.",
              visible: indicator?.isBackgroundCall || false,
              ...(isCustomerTable ? { customParams: { category, filterKey, idAcess, disregardedIds } } : {}),
            },
          };
        });
        // Atualiza os resultados conforme sua lógica
        if (isCustomerTable) {
          const isNetworkOnly = options.fetchPolicy === "network-only";
          setResults((prev) => {
            const oldData = { ...(prev[key]?.data || {}) };
            const currentCategory = category;
            const currentFilter = filterKey || "sem-filtro";
            const currentId = idAcess;
            if (isNetworkOnly) {
              const catObj = oldData[currentCategory as string];
              if (catObj && catObj[currentFilter] && catObj[currentFilter][currentId as string]) {
                delete catObj[currentFilter][currentId as string];
              }
            }
            const oldDataCategory = oldData[currentCategory as string] || {};
            const oldFilterVal = oldDataCategory[currentFilter] || {};
            return {
              ...prev,
              [key]: {
                ...prev[key],
                loading: false,
                data: {
                  ...oldData,
                  [currentCategory as string]: {
                    ...oldDataCategory,
                    [currentFilter]: {
                      ...oldFilterVal,
                      [currentId as string]: {
                        data: res?.data,
                      },
                    },
                  },
                },
              },
            };
          });
        } else {
          setResults((prev) => ({
            ...prev,
            [key]: { ...prev[key], data: res?.data, loading: false },
          }));
        }
        return res;
      } catch (err) {
        console.error(`Erro na query '${key}':`, err);
        setRequestIndicators((prev) => {
          const indicator = prev[key];
          return {
            ...prev,
            [key]: {
              ...indicator,
              status: "error",
              message: "Erro na requisição.",
              visible: indicator?.isBackgroundCall || false,
            },
          };
        });
        setResults((prev) => ({
          ...prev,
          [key]: { ...prev[key], error: err, loading: false },
        }));
        return null;
      } finally {
        clearTimeout(controlTimer);
        // Caso os timers internos já tenham sido agendados, eles serão cancelados se necessário (os scheduleTimeouts retornam os timer IDs e, se a requisição termina, esses timers serão cancelados dentro do bloco finally no código original, se você os armazenar)
      }
    },
    [allActions, requestIndicators]
  );
  // Factory para criar funções lazy sem parâmetros extras
  const createLazyQuery = <TData, TVariables extends OperationVariables>(key: string) => {
    return ((options = {}) => {
      return wrappedFetch(key, {
        variables: options.variables || {},
        fetchPolicy: options.fetchPolicy as FetchPolicy,
      });
    }) as LazyQueryExecFunction<TData, TVariables>;
  };
  // Factory para criar funções lazy com parâmetros extras (meta)
  const createExtendedLazyQuery = <TData, TVariables extends OperationVariables>(key: string) => {
    return ((options = {}) => {
      const { category, idAcess, filterKey, ...variables } = options.variables || {};
      return wrappedFetch(key, {
        variables: variables || {},
        fetchPolicy: options.fetchPolicy as FetchPolicy,
        category: category,
        filterKey: filterKey,
        idAcess: idAcess,
      });
    }) as ExtendedLazyQueryExecFunction<TData, TVariables & CustomParams>;
  };
  // Cria as funções de query
  const getCustomersTableWithFetch = createExtendedLazyQuery<{ Customers: any }, OperationVariables>(
    "getCustomersTable"
  );
  const getCustomerByIdWithFetch = createLazyQuery<{ Customers: { data: any } }, { id: number; businessIds: number[] }>(
    "getCustomerById"
  );
  const getDashTopProductsWithFetch = createLazyQuery<{ ProductGroupSales: any }, OperationVariables>(
    "getDashTopProducts"
  );
  const getDashClustersWithFetch = createLazyQuery<{ dashboardCustomers: any }, OperationVariables>("getDashClusters");
  const getDashProfileWithFetch = createLazyQuery<{ CustomerProfile: any }, OperationVariables>("getDashProfile");
  const getCustomersComplementTableWithFetch = createLazyQuery<{ Customers: any }, OperationVariables>(
    "getCustomersComplementTable"
  );
  const getRegistrationPctWithFetch = createLazyQuery<{ DashboardNetworkPurchases: any }, OperationVariables>(
    "getRegistrationPct"
  );

  return {
    getCustomerById: getCustomerByIdWithFetch,
    getDashTopProducts: getDashTopProductsWithFetch,
    getDashClusters: getDashClustersWithFetch,
    getDashProfile: getDashProfileWithFetch,
    getCustomersComplementTable: getCustomersComplementTableWithFetch,
    getCustomersTable: getCustomersTableWithFetch,
    getRegistrationPct: getRegistrationPctWithFetch,
    results,
    requestIndicators,
    setRequestIndicators,
  };
}

export default usePreserveData;
