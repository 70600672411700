import { Suspense } from "react";
import { useSelector } from "react-redux";
import { isGsGroup } from "@/services/utils";
import { LinearProgress } from "@mui/material";
import ProtectedRoute from "@routes/ProtectedRoute";
import { useGlobalData } from "@context/GlobalContext";
import { ICompanyStore } from "@store/Company/CompanyReducer";
import { IUserStore } from "@store/CurrentUser/CurrentUserReducer";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import RouteLoadingPage from "@pages/RouteLoadingPage/RouteLoadingPage";
import {
  canRequestHotleadsActivation,
  companySelected,
  getUserBbAgroEsg,
  isAdmin,
  getToken,
  isCustomerService,
  isManager,
  isOwner,
  isSeller,
  isWorker,
} from "@services/auth";
import {
  ActivationsLazy,
  BillingLazy,
  BusinessRegister1Lazy,
  BusinessRegisterLazy,
  CampaignsLazy,
  ConfigurationsLazy,
  CustomersB2BLazy,
  CustomersLazy,
  DataLazy,
  // EnrichmentLazy,
  esgBbAgroLazy,
  EsgPageLazy,
  HomeLazy,
  HomeLightLazy,
  HomeLightV1Lazy,
  HotLeadsLazy,
  InsightsLazy,
  IntegrationsLazy,
  LandingPagesLazy,
  LoadingPage,
  NetworkDashboardLazy,
  NewOfferLazy,
  NoiseControlLazy,
  OffersLazy,
  ProductsLazy,
  ReportsLazy,
  SalesLazy,
  SocialMediaLazy,
  // UpdatesLazy,
  Welcome,
  WhatsAppWebLazy,
  RegisterBusinessV1Lazy,
  BusinessGroupSelectV1Lazy,
  BusinessSelectV1Lazy,
  IntegrationLazy,
  EsgAdminLazy,
} from "@pages";
import LayoutContainer from "@containers/LayoutContainer/LayoutContainer";

interface IPrivateRouteProps extends RouteProps {
  component?: React.FC<any>;
  path: string;
}

function PrivateRoute({ ...props }: IPrivateRouteProps) {
  const groupSubscriptionInfo = useSelector((store: IUserStore) => store.user.subscription);
  const loadingGlobal = useSelector((store: ICompanyStore) => store.company.loading);
  const paymentOK = groupSubscriptionInfo.allowAccess;

  const { currentPlan } = useGlobalData();
  const isSubscriptionActive = paymentOK || currentPlan.isLoading || !!currentPlan.data?.active;

  const isSellerUser = isSeller();
  const isAdm = isAdmin();
  const isDefaultUser = isOwner() || isAdm || isManager() || isWorker() || isCustomerService();
  const isBbAgroUser = companySelected() === 4667 && getUserBbAgroEsg();

  const loadingLinear = (
    <LinearProgress sx={{ backgroundColor: "white", "& .MuiLinearProgress-bar": { backgroundColor: "#C4C4C4" } }} />
  );

  return (
    <LayoutContainer>
      <Suspense fallback={<RouteLoadingPage />}>
        <Route
          {...props}
          render={({ location, match: { url } }) => {
            if (currentPlan.isLoading) {
              return <Route path={`${url}/*`} component={LoadingPage} />;
            }

            if (getToken() === null) {
              window.location.href = "/login";
            }

            if (loadingGlobal) {
              return <Route path={`${url}/*`} component={LoadingPage} />;
            }

            if (isSellerUser) {
              return (
                <Switch>
                  <Route path={`${url}/customers`} component={CustomersLazy} />
                  <Redirect from={`${url}/*`} to={`${url}/customers`} />
                </Switch>
              );
            }

            if (isBbAgroUser) {
              return (
                <Switch>
                  <Route path={`${url}/esg-bb-agro`} component={esgBbAgroLazy} />
                  <Redirect from={`${url}/*`} to={`${url}/esg-bb-agro`} />
                </Switch>
              );
            }

            return (
              <Switch>
                <ProtectedRoute
                  path={`${url}/business-register-v1`}
                  component={RegisterBusinessV1Lazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/billing`}
                  component={BillingLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/configurations`}
                  component={ConfigurationsLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/business-group-select-v1`}
                  component={BusinessGroupSelectV1Lazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/business-select-v1`}
                  component={BusinessSelectV1Lazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/home`}
                  component={HomeLightLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/home-v1`}
                  component={HomeLightV1Lazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/home-left`}
                  component={HomeLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/dashboard`}
                  component={NetworkDashboardLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/businessregister`}
                  component={BusinessRegisterLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                  suspenseFallback={loadingLinear}
                  exact
                />
                <ProtectedRoute
                  path={`${url}/esg-admin`}
                  component={EsgAdminLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                  suspenseFallback={loadingLinear}
                  exact
                />
                <ProtectedRoute
                  path={`${url}/businessregister/:groupId`}
                  component={BusinessRegisterLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                  suspenseFallback={loadingLinear}
                />
                <ProtectedRoute
                  path={`${url}/customers`}
                  component={CustomersLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/customers-b2b`}
                  component={CustomersB2BLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/sales`}
                  component={SalesLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/socialmedia`}
                  component={SocialMediaLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isAdm}
                />
                <ProtectedRoute
                  path={`${url}/campaigns`}
                  component={CampaignsLazy}
                  exact
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/activations`}
                  component={ActivationsLazy}
                  exact
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/offers`}
                  component={OffersLazy}
                  exact
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/offers/new`}
                  component={NewOfferLazy}
                  exact
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/offers/:id`}
                  component={NewOfferLazy}
                  exact
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/products`}
                  component={ProductsLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/data`}
                  component={DataLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/integration-v1`}
                  component={IntegrationLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/configurations`}
                  component={ConfigurationsLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/insights`}
                  component={InsightsLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/integrations`}
                  component={IntegrationsLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/noise-control`}
                  component={NoiseControlLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/landing-pages`}
                  component={LandingPagesLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/whatsappweb`}
                  component={WhatsAppWebLazy}
                  suspenseFallback={loadingLinear}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                <ProtectedRoute
                  path={`${url}/reports`}
                  component={ReportsLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                {/* <ProtectedRoute
                path={`${url}/updates`}
                component={UpdatesLazy}
                location={location}
                isPaymentOk={isSubscriptionActive}
                isEnabled={isDefaultUser}
              /> */}
                <ProtectedRoute
                  path={`${url}/esg`}
                  component={EsgPageLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser}
                />
                {/* <ProtectedRoute
                path={`${url}/enrichment`}
                component={EnrichmentLazy}
                location={location}
                isPaymentOk={isSubscriptionActive}
                isEnabled={isDefaultUser}
              /> */}
                <ProtectedRoute
                  path={`${url}/hotleads`}
                  component={HotLeadsLazy}
                  location={location}
                  isPaymentOk={isSubscriptionActive}
                  isEnabled={isDefaultUser && (isGsGroup() || canRequestHotleadsActivation())}
                />

                <Redirect from={`${url}/*`} to={`${url}/home`} />
                <Redirect from={`${url}`} to={`${url}/home`} />
              </Switch>
            );
          }}
        />
      </Suspense>
    </LayoutContainer>
  );
}

export default PrivateRoute;
