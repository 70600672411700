import "./styles.scss";
import { clsx } from "clsx";
import { Collapse } from "@mui/material";
import { IBiudMenuItemGroupProps } from "./types";
import BiudMenuItem from "@components/BiudMenuItem";

function BiudMenuItemGroup({
  icon,
  title,
  onClick = () => "",
  isOpen = false,
  children,
  isDisabled = false,
  containerProps = {},
  isBlackMode = false,
  ...rest
}: IBiudMenuItemGroupProps) {
  return (
    <section
      className={clsx("biud-menu-item-group-container", isBlackMode && "group-container-dark")}
      {...containerProps}
    >
      <BiudMenuItem
        title={title}
        icon={icon}
        onClick={onClick}
        haveSubItem={true}
        isOpen={isOpen}
        isDisabled={isDisabled}
        isBlackMode={isBlackMode}
      />
      <Collapse in={isOpen} style={{ width: "100%" }}>
        <div className="menu-collapse-container" {...rest}>
          {children}
        </div>
      </Collapse>
    </section>
  );
}

export type { IBiudMenuItemGroupProps };
export default BiudMenuItemGroup;
