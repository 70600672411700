import { useEffect, useLayoutEffect } from "react";
import "./App.scss";
import "@pages/Customers/styles.scss";
import "@pages/CustomersB2B/styles.scss";
import "@components/Configuration/ConfigurationsContent/styles.css";
import moment from "moment";
import Routes from "./routes";
import { store } from "@store/store";
import { Provider } from "react-redux";
import "rc-tooltip/assets/bootstrap.css";
import TagManager from "react-gtm-module";
import { client } from "./graphql/apollo";
import { ApolloProvider } from "@apollo/client";
import "react-loading-skeleton/dist/skeleton.css";
import GlobalDataProvider from "@context/GlobalContext";
import Extensions from "./services/data-extensions/Extensions";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { changeRootTheme, getDefaultTheme } from "@/themes/utils";
import AnalyticScripts from "@genericss/Scripts";

moment.locale("pt-br");

Extensions.all();

function App() {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-PM69LWX",
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  const currentTheme = getDefaultTheme();

  const theme = createTheme({
    palette: {
      primary: {
        light: currentTheme !== "sebrae" ? "#80c4ff" : "#8FB8EF",
        main: currentTheme !== "sebrae" ? "#0095ff" : "#005EB8",
        dark: currentTheme !== "sebrae" ? "#007acc" : "#005EB8",
        contrastText: "#FFFFFF",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
      mode: "light",
    },
    components: {
      MuiTextField: {
        defaultProps: {
          variant: "standard",
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            zIndex: 10,
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            "& .MuiTableCell-head": {
              fontSize: "0.75em",
              fontWeight: 700,
              border: "none",
              fontFamily: "'Outfit', sans-serif",
              color: "#909094",
              "& .Mui-active": {
                color: "#0095FF",
                fontWeight: 700,
              },
            },
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            "& .MuiTableCell-body": {
              fontSize: "0.9em",
              border: "none",
              fontFamily: "'Outfit', sans-serif",
              color: "#666666",
              "&:nth-of-type(2)": {
                color: "black",
              },
              "&:nth-of-type(5)": {
                cursor: "default",
              },
              "&:nth-of-type(7)": {
                cursor: "default",
              },
              cursor: "pointer",
            },
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            color: "#000000",
            "&:hover": {
              color: "#909094",
            },
          },
          active: {},
          icon: {
            color: "inherit !important",
          },
        },
      },
    },
    typography: {
      fontFamily: ['"Outfit"', "sans-serif", '"Helvetica"', "Arial"].join(","),
      fontSize: 14,
      body1: {
        color: "#000000",
      },
    },
  });

  const queryClient = new QueryClient();

  useLayoutEffect(() => {
    changeRootTheme(getDefaultTheme());
  }, []);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <GlobalDataProvider>
              <AnalyticScripts />
              <Routes />
            </GlobalDataProvider>
          </Provider>
        </QueryClientProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
