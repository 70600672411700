import { useMemo } from "react";
import "./styles.scss";
import { Avatar } from "@mui/material";
import { getAbbr2 } from "@services/utils";
import { StyledOnlineBadge } from "./styles";
import { handleFontSize, handleSize } from "./helper";
import { AvatarProps } from "@mui/material/Avatar/Avatar";

export interface IBiudAvatarProps extends AvatarProps {
  name?: string;
  isOnline?: boolean;
  type?: "circular" | "rounded" | "square";
  size?: "xs" | "sm" | "md" | "lg";
}

function BiudAvatar({ name, isOnline, type, children, variant, size, ...rest }: IBiudAvatarProps) {
  const avatarType = type || variant;

  const avatarSize = useMemo(() => handleSize(size || "md"), [size]);
  const fontSize = useMemo(() => handleFontSize(size || "md"), [size]);

  if (isOnline) {
    return (
      <StyledOnlineBadge
        overlap={avatarType === "circular" ? "circular" : "rectangular"}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="dot"
      >
        <Avatar alt="avatar" variant={avatarType} sx={{ width: avatarSize, height: avatarSize, fontSize }} {...rest}>
          {children || (name ? getAbbr2(name) : undefined)}
        </Avatar>
      </StyledOnlineBadge>
    );
  }

  return (
    <Avatar alt="avatar" variant={avatarType} sx={{ width: avatarSize, height: avatarSize, fontSize }} {...rest}>
      {children || (name ? getAbbr2(name) : undefined)}
    </Avatar>
  );
}

export default BiudAvatar;
